import axios from "axios";
import headers from "~/utils/headers";

const getIcebreakers = async () => {
  const res = await axios.get(`/api/organization/icebreakers`);
  return res.data;
};

const createIcebreaker = async (question) => {
  const res = await axios.post(
    `/api/organization/icebreakers`,
    { question },
    headers(),
  );
  return res.data;
};

const deleteIcebreaker = async (payload) => {
  const res = await axios.delete(
    `/api/organization/icebreakers/${payload}`,
    headers(),
  );
  return res.data;
};

export { getIcebreakers, createIcebreaker, deleteIcebreaker };
