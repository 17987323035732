import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import ContactPropertyPickerField from "~/components/fields/ContactPropertyPickerField";
import InputCheckbox from "../../elements/InputCheckbox";
import InputSelect from "../../elements/InputSelect";
import AutoInput from "../../utils/AutoInput";

const fieldTypes = [
  "text",
  "email",
  "url",
  "phone",
  "date",
  "number",
  "textarea",
  "multiple_choice",
];

export default function QuestionsSurveyFormQuestion(props) {
  const { t } = useTranslation();

  const { question, onChange, onRemove } = props;

  const handleChange = (field) => (value) => {
    onChange({ ...question, [field]: value });
  };

  // Mutliple choice options

  const handleAddOption = () => {
    onChange({
      ...question,
      options: [...(question.options || []), { title: "" }],
    });
  };

  const handleChangeOption = (index, value) => {
    onChange({
      ...question,
      options: question.options.map((o, i) =>
        i === index ? { ...o, title: value } : o,
      ),
    });
  };

  const fieldTypeOptions = fieldTypes.map((type) => ({
    label: t(`surveys.questions.field_types.${type}`),
    value: type,
  }));

  return (
    <div className="border p-2 rounded-xl relative group mb-3">
      <button
        className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center bg-white border shadow rounded-full text-darker-gray opacity-0 group-hover:opacity-100 transition-opacity"
        onClick={onRemove}
      >
        <XMarkIcon className="w-3" />
      </button>
      <div className="space-y-2">
        <Input
          placeholder={t(`surveys.questions.title_placeholder`)}
          value={question.title}
          onChange={handleChange("title")}
          autoFocus={!question.title}
          emojiPicker
        />
        <div className="flex space-x-3 items-center">
          <div className="flex-grow">
            <InputSelect
              options={fieldTypeOptions}
              value={question.field_type}
              onChange={handleChange("field_type")}
            />
          </div>
          <InputCheckbox
            value={question.required}
            onClick={handleChange("required")}
            label={t("shared.required")}
          />
        </div>
        {question.field_type === "multiple_choice" && (
          <>
            <InputCheckbox
              label={t("surveys.questions.multiple_choice.accept_multiple")}
              value={question.accept_multiple}
              onClick={handleChange("accept_multiple")}
            />
            <div className="flex gap-2 flex-wrap justify-start">
              {(question.options || []).map((option, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-1 border rounded-2xl bg-white pl-2.5 pr-2 py-1"
                >
                  <AutoInput
                    className="min-w-[20px] border-none bg-none outline-none p-0 focus:outline-none focus:ring-0 text-sm"
                    value={option.title}
                    onChange={(evt) =>
                      handleChangeOption(index, evt.target.value)
                    }
                    autoFocus={option.title === ""}
                    placeholder={t(
                      "automation.scenarios.actions.ask_question.option_placeholder",
                    )}
                  />
                  <XMarkIcon
                    className="w-4 opacity-75 transition-opacity hover:opacity-100 cursor-pointer"
                    onClick={onRemove}
                  />
                </div>
              ))}
              {(question.options || []).length < 3 && (
                <button
                  className="nodrag nopan flex-shrink-0 flex items-center space-x-1.5 border rounded-2xl bg-white pl-2 pr-2.5 py-1 text-sm whitespace-nowrap hover:bg-neutral-50 transition-colors"
                  onClick={handleAddOption}
                >
                  <PlusIcon className="w-4" />
                  {t("surveys.questions.multiple_choice.add_option")}
                </button>
              )}
            </div>
          </>
        )}
        <div className="flex items-center space-x-3 mb-2 overflow-hidden">
          <div className="text-xs text-right flex-grow whitespace-nowrap flex-shrink-0">
            {t("surveys.questions.save_to_property")}
          </div>
          <ContactPropertyPickerField
            value={question.contact_property}
            onChange={handleChange("contact_property")}
          />
        </div>
      </div>
    </div>
  );
}
