import React, { useContext, useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import Button from "~/components/elements/Button";
import { useTranslation } from "react-i18next";
import { ArrowUpTrayIcon, MicrophoneIcon } from "@heroicons/react/24/outline";
import { MediaFilesContext } from "~/contexts/media-files-context";
import AudioRecordForm from "./AudioRecordForm";

export default function AudioMessageModal(props) {
  const { onClose, onCreate } = props;
  const fileInputRef = useRef(null);

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const { createMedia } = useContext(MediaFilesContext);

  // animate appear
  useEffect(() => {
    setTimeout(() => setVisible(true), 50);
  }, []);

  const handleClose = () => {
    setVisible(false);
    setTimeout(onClose, 150);
  };

  const [uploading, setUploading] = useState(false);

  const uploadMedia = async () => {
    setUploading(true);

    const input = fileInputRef.current;
    if (input.files.length == 0) {
      input.value = null;
      setUploading(false);
      return;
    }

    const file = input.files.item(0);
    const size = Math.round(file.size / 1024);
    if (size > 24576) {
      window.alert(t("inbox.conversation.max_file_size.audio"));
      input.value = null;
      setUploading(false);
      return;
    }

    const fileType = file.type.split("/")[0];
    if (fileType !== "audio") {
      window.alert(t("inbox.conversation.invalid_file_type"));
      input.value = null;
      setUploading(false);
      return;
    }

    const media = await createMedia(file, fileType);
    setUploading(false);
    return media;
  };

  const handleUpload = async () => {
    const media = await uploadMedia();
    onCreate(media);
    handleClose();
  };

  const handleRecord = async (file) => {
    const media = await createMedia(file, "audio");
    onCreate(media);
    handleClose();
  };

  const [showAudioForm, setShowAudioForm] = useState(false);

  const cancelAudio = () => {
    setShowAudioForm(false);
  };

  return createPortal(
    <div
      className={`fixed inset-0 flex flex-col justify-center p-4 select-none ${
        visible ? "pointer-events-auto" : "pointer-events-none"
      }`}
      style={{
        zIndex: 1000,
      }}
    >
      <div
        className={`absolute inset-0 bg-black/30 transition-opacity ${
          visible ? "opacity-100" : "opacity-0"
        }`}
        onClick={handleClose}
      />
      <div
        className={`w-full max-w-md m-auto bg-white p-4 flex flex-col gap-y-4 items-center rounded-xl relative transform-gpu transition-all ${
          visible ? "scale-100 opacity-100" : "scale-95 opacity-0"
        }`}
      >
        <div className="self-start text-md font-medium">
          {t("inbox.audio_messages.modal.title")}
        </div>
        <label className="flex justify-between items-center flex-shrink-0 cursor-pointer">
          <Button
            label={t("inbox.audio_messages.modal.upload_file")}
            icon={ArrowUpTrayIcon}
            onClick={() => {
              fileInputRef.current.click();
            }}
            className="flex items-center gap-x-1.5 font-normal"
            loading={uploading}
          />
          <input
            type="file"
            accept="audio/*"
            className="hidden"
            name="attachment_file"
            onChange={handleUpload}
            ref={fileInputRef}
          />
        </label>
        <p className="text-dark-gray">
          {t("inbox.audio_messages.modal.record_audio")}
        </p>
        {showAudioForm ? (
          <AudioRecordForm
            onCancel={cancelAudio}
            onRecord={(audioRecording) => {
              handleRecord(audioRecording);
            }}
          />
        ) : (
          <Button
            rounded
            icon={MicrophoneIcon}
            style="primary"
            onClick={() => setShowAudioForm(true)}
          />
        )}
      </div>
    </div>,
    document.body,
  );
}
