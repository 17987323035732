import { BoltIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlowContext } from "~/contexts/flow-context";
import CommentOptions from "./CommentOptions";
import MessagesOptions from "./MessagesOptions";
import OutgoingMessageOptions from "./OutgoingMessageOptions";
import ReferralAdOptions from "./ReferralAdOptions";
import ReferralOptions from "./ReferralOptions";
import StoryReplyOptions from "./StoryReplyOptions";
import TriggerTypeMenu from "./TriggerTypeMenu";
import WebhookOptions from "./WebhookOptions";
import FolderOptions from "./FolderOptions";
import CustomEventOptions from "./CustomEventOptions";
import LiveCommentOptions from "./LiveCommentOptions";
import IcebreakerOptions from "./IcebreakerOptions";

export default function TriggerItem(props) {
  const { t } = useTranslation();

  const { trigger } = props;
  const { editTrigger, removeTrigger } = useContext(FlowContext);

  const [triggerTypeMenu, showTriggerTypeMenu] = useState(false);

  const handlePickTriggerType = (triggerType) => {
    editTrigger(trigger.id, { trigger_type: triggerType });
  };

  const typeOptions = {
    messages: MessagesOptions,
    story_reply: StoryReplyOptions,
    referral_ad: ReferralAdOptions,
    icebreaker: IcebreakerOptions,
    comment: CommentOptions,
    live_comment: LiveCommentOptions,
    referral: ReferralOptions,
    outgoing_message: OutgoingMessageOptions,
    webhook: WebhookOptions,
    folder: FolderOptions,
    custom_event: CustomEventOptions,
  };

  const Options = typeOptions[trigger.trigger_type];

  return (
    <>
      <div className="py-4 px-4 bg-white border-2 border-automation border-dashed rounded-2xl flex flex-col gap-y-4 relative group">
        <button
          className="absolute -top-2 -right-2 w-6 h-6 flex items-center justify-center bg-white border shadow rounded-full text-darker-gray opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={() => removeTrigger(trigger.id)}
        >
          <XMarkIcon className="w-4" />
        </button>
        <div className="flex items-center space-x-1.5">
          <div className="whitespace-nowrap">
            {t(`automation.scenarios.trigger_node.when`)}
          </div>
          <button
            className="px-1.5 rounded bg-purple-100 text-purple-900 text-sm nodrag flex items-center space-x-1"
            onClick={() => showTriggerTypeMenu(true)}
            disabled={!trigger.id.toString().includes("temp")}
          >
            <BoltIcon className="w-4 text-purple-500" />
            <div className="font-medium pb-0.5 whitespace-nowrap">
              {t(
                `automation.scenarios.trigger_type.${trigger.trigger_type}.label`,
              )}
            </div>
          </button>
        </div>
        {Options && <Options trigger={trigger} />}
      </div>
      {triggerTypeMenu && (
        <TriggerTypeMenu
          onSubmit={handlePickTriggerType}
          onClose={() => showTriggerTypeMenu(false)}
        />
      )}
    </>
  );
}
