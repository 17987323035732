import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  ReactFlow,
  Background,
  useEdgesState,
  useNodesState,
  useReactFlow,
  Controls,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import { DirtyContext } from "~/contexts/dirty-context";
import { FlowContext } from "~/contexts/flow-context";
import AddActionMenu from "./AddActionMenu";
import InsertableEdge from "./InsertableEdge";
import NormalEdge from "./NormalEdge";
import generateNodesAndEdges from "./generateNodesAndEdges";
import OptionEdge from "./OptionEdge";
import { compact } from "lodash";
import nodeTypes from "./nodeTypes";

export default function Flow(props) {
  const {
    hasTriggers = false,
    triggers,
    setTriggers,
    actions = [],
    setActions = () => {},
    children,
  } = props;

  const { setDirty } = useContext(DirtyContext);

  // Triggers

  const addTrigger = (trigger) => {
    const newTrigger = {
      ...trigger,
      id: `temp_${triggers?.length + 1}`,
    };
    setTriggers((triggers) => [...triggers, newTrigger]);
    setDirty(true);
  };

  const editTrigger = (id, newTrigger) => {
    setTriggers((triggers) =>
      triggers?.map((trigger) => {
        if (trigger.id == id) {
          return { ...trigger, ...newTrigger };
        } else {
          return trigger;
        }
      }),
    );
    setDirty(true);
  };

  const editTriggerOptions = (id, newOptions) => {
    setTriggers((triggers) =>
      triggers?.map((trigger) => {
        if (trigger.id == id) {
          return { ...trigger, options: { ...trigger.options, ...newOptions } };
        } else {
          return trigger;
        }
      }),
    );
    setDirty(true);
  };

  const removeTrigger = (id) => {
    if (id.toString().includes("temp_")) {
      setTriggers((triggers) =>
        triggers?.filter((trigger) => trigger.id != id),
      );
    } else {
      setTriggers((triggers) =>
        triggers?.map((trigger) => {
          if (trigger.id == id) {
            return { ...trigger, _destroy: true };
          } else {
            return trigger;
          }
        }),
      );
    }
    setDirty(true);
  };

  // Actions

  const addAction = (newAction, parentId, parentBranch = null) => {
    // generate a new temporary id for the new action
    // by getting the highest temporary id and adding 1
    const tempIds = actions
      .filter((a) => a.id.toString().includes("temp_"))
      .map((a) => parseInt(a.id.split("_")[1]));
    const newTempId = tempIds.length > 0 ? Math.max(...tempIds) + 1 : 1;
    const newActionPayload = {
      ...newAction,
      parent_id: parentId,
      parent_branch: parentBranch,
      id: `temp_${newTempId}`,
    };
    setDirty(true);
    setActions((actions) => {
      // update children parent_id and parent_branch
      const updatedActions = actions.map((action) => {
        // when action was the first in the branch, set it as a child of the new action
        // if needed, set its parent_branch as the default_branch of the new action
        // if there is no default_branch, it will be set to null
        if (
          action.parent_id == parentId &&
          action.parent_branch == parentBranch &&
          !action._destroy
        ) {
          return {
            ...action,
            parent_id: newActionPayload.id,
            parent_branch: newAction?.default_branch,
          };
        } else {
          return action;
        }
      });
      return [...updatedActions, newActionPayload];
    });
  };

  const editAction = (id, newAction, dirty = true) => {
    setActions((actions) =>
      actions.map((action) => {
        if (action.id == id) {
          return { ...action, ...newAction };
        } else {
          return action;
        }
      }),
    );
    dirty && setDirty(true);
  };

  const removeAction = (id, deleteChildren = false) => {
    const removedAction = actions.find((action) => action.id == id);
    if (id.toString().includes("temp_")) {
      setActions((actions) => actions.filter((action) => action.id != id));
    } else {
      setActions((actions) =>
        actions.map((action) => {
          if (action.id == id) {
            return { ...action, _destroy: true, parent_id: null };
          } else {
            return action;
          }
        }),
      );
    }
    setDirty(true);

    // delete/update children actions
    setActions((actions) =>
      compact(
        actions.map((action) =>
          action.parent_id == id
            ? deleteChildren
              ? null
              : {
                  ...action,
                  parent_id: removedAction.parent_id,
                  parent_branch: removedAction.parent_branch,
                }
            : action,
        ),
      ),
    );
  };

  // Actions

  const handleDoubleClick = (evt, node) => {
    if (["delay", "comment"].includes(node.type)) {
      editAction(node.data.id, { editable: true });
    }
  };

  const handleNodesDelete = (nodes) => {
    nodes.forEach((node) => removeAction(node.data.id));
  };

  const handleEdgeClick = (evt, edge) => {
    if (edge.type == "insertable") {
      let parentId, parentBranch;
      // edges connected to the start of a branch
      if (edge.source.includes("_option_")) {
        parentId = edge.source.match(/(.+)_option/)[1];
        parentBranch = edge.source.match(/_(option_.+)/)[1];
        // edges connected to trigger node
      } else if (edge.source == "trigger") {
        parentId = null;
        // other edges
      } else {
        parentId = edge.source;
      }
      showAddActionMenu({
        onSubmit: (action) => addAction(action, parentId, parentBranch),
      });
    }
  };

  // Flow
  const { fitView } = useReactFlow();

  // Nodes & edges

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [nodeDimensions, setNodeDimensions] = useState({});
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  useEffect(async () => {
    const { nodes, edges } = await generateNodesAndEdges(
      hasTriggers,
      actions,
      triggers,
      nodeDimensions,
    );
    setNodes(nodes);
    setEdges(edges);
  }, [hasTriggers, actions, triggers, nodeDimensions]);

  // Node types with a function to set their height from the child node
  const nodeTypesWithDimensions = useMemo(
    () =>
      Object.entries(nodeTypes).reduce((acc, [nodeType, NodeComponent]) => {
        acc[nodeType] = (props) => (
          <NodeComponent
            {...props}
            nodeId={props.id}
            setNodeDimensions={setNodeDimensions}
          />
        );
        return acc;
      }, {}),
    [nodeTypes, setNodeDimensions],
  );

  const edgeTypes = useMemo(
    () => ({
      normal: NormalEdge,
      insertable: InsertableEdge,
      option: OptionEdge,
    }),
    [],
  );

  // Fit view
  const fixedFitView = useCallback(() => {
    fitView({ minZoom: 1, maxZoom: 1 });
  }, [fitView]);

  useEffect(() => {
    window.addEventListener("resize", fixedFitView);
    return () => window.removeEventListener("resize", fixedFitView);
  }, [fitView]);

  const [addActionMenu, showAddActionMenu] = useState(false);

  const contextValues = {
    hasTriggers,
    triggers,
    addTrigger,
    editTrigger,
    editTriggerOptions,
    removeTrigger,
    showAddActionMenu,
    actions,
    addAction,
    editAction,
    removeAction,
  };

  return (
    <FlowContext.Provider value={contextValues}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={false}
        // panOnDrag={false}
        edgesFocusable={false}
        selectionMode="partial"
        panOnScroll={true}
        panOnScrollMode="free"
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        nodeTypes={nodeTypesWithDimensions}
        edgeTypes={edgeTypes}
        connectionRadius={0}
        onNodeDoubleClick={handleDoubleClick}
        onNodesDelete={handleNodesDelete}
        onEdgeClick={handleEdgeClick}
        // defaultViewport={{
        //   x: 0,
        //   y: 40,
        //   zoom: 1,
        // }}
        fitView
        fitViewOptions={{
          minZoom: 1,
          maxZoom: 1,
        }}
      >
        <Background color="#999" variant="dots" />
        <Controls
          showFitView={false}
          showInteractive={false}
          position="bottom-right"
        />
        {children}
      </ReactFlow>
      {addActionMenu && (
        <AddActionMenu
          onSubmit={addActionMenu.onSubmit || addAction}
          onClose={() => showAddActionMenu(false)}
        />
      )}
    </FlowContext.Provider>
  );
}
