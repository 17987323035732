import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user-context";
import Modal from "../../shared/Modal";
import { t } from "i18next";
import { isEqual } from "lodash";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { UIContext } from "../../../contexts/ui-context";
import OptOutKeywords from "./OptOutKeywords";

export default function OptOutModal(props) {
  const { onClose = () => {} } = props;

  const { showPrompt } = useContext(UIContext);
  const { organization, updateOrganization } = useContext(UserContext);
  const { optout_options } = organization;

  const [options, setOptions] = useState(
    optout_options || {
      menu_message: "",
      confirmation_message: "",
      keywords: [],
    },
  );

  useEffect(() => {
    if (!isEqual(optout_options, options)) setOptions(optout_options);
  }, [optout_options]);

  const handleSave = () => {
    updateOrganization({ optout_options: options });
    if (!options.active) {
      showPrompt(
        t("opt_out.activate_prompt"),
        () => {
          updateOrganization({ optout_options: { ...options, active: true } });
          onClose();
        },
        onClose,
      );
    } else {
      onClose();
    }
  };

  return (
    <Modal
      onClose={onClose}
      title={t("opt_out.title")}
      className="sm:w-full max-w-[440px]"
      footerLeft={<Button label={t("shared.cancel")} onClick={onClose} />}
      footerRight={
        <Button
          style="primary"
          label={t("shared.save")}
          loadingOnClick
          onClick={handleSave}
        />
      }
    >
      <div className="divide-y">
        <div className="p-4 space-y-2">
          <div className="font-medium">{t("opt_out.menu_message")}</div>
          <Input
            placeholder={t("opt_out.menu_message_placeholder")}
            value={options?.menu_message}
            onChange={(value) =>
              setOptions({ ...options, menu_message: value })
            }
          />
          <div className="flex space-x-1">
            <QuestionMarkCircleIcon className="w-4 text-neutral-500" />
            <a
              href="https://developers.facebook.com/docs/messenger-platform/instagram/features/persistent-menu"
              target="_blank"
              className="block text-xs text-neutral-600 underline"
            >
              {t("persistent_menu.learn_more")}
            </a>
          </div>
        </div>
        <OptOutKeywords
          keywords={options.keywords}
          updateKeywords={(keywords) => setOptions({ ...options, keywords })}
        />
        <div className="p-4 space-y-2">
          <div className="font-medium">{t("opt_out.confirmation_message")}</div>
          <Input
            type="textarea"
            rows={2}
            placeholder={t("opt_out.confirmation_message_placeholder")}
            value={options?.confirmation_message}
            onChange={(value) =>
              setOptions({ ...options, confirmation_message: value })
            }
          />
        </div>
      </div>
    </Modal>
  );
}
