import React from "react";
import { Switch } from "@headlessui/react";
import classNames from "~/utils/classNames";

const wrapperSizes = {
  base: "h-6 w-10",
  small: "h-5 w-9",
};

const indicatorSizes = {
  base: "h-5 w-5",
  small: "h-4 w-4",
};

const translateSizes = {
  base: "translate-x-4",
  small: "translate-x-4",
};

const colors = {
  primary: "bg-primary",
  red: "bg-red-400",
  yellow: "bg-yellow-500",
};

export default function Toggle(props) {
  const {
    value,
    onChange,
    disabled = false,
    size = "base",
    color = "primary",
  } = props;

  return (
    <Switch
      checked={value || false}
      onChange={onChange}
      className={classNames(
        value ? colors[color] : "bg-neutral-200",
        disabled ? "pointer-events-none opacity-50" : "",
        "relative inline-flex flex-shrink-0 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2",
        wrapperSizes[size],
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          value ? translateSizes[size] : "translate-x-0",
          "pointer-events-none inline-block rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
          indicatorSizes[size],
        )}
      />
    </Switch>
  );
}
