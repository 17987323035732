import { MarkerType } from "@xyflow/react";
import { actionNodeHeight, triggerNodeHeight } from "./nodes/nodeHeight";
import ELK from "elkjs/lib/elk.bundled";
import { identity } from "lodash";

const elk = new ELK();

const NODE_WIDTH = 280;

export default async function generateNodesAndEdges(
  hasTriggers,
  actions,
  triggers,
  nodeDimensions,
  editable = true,
) {
  const nodes = [];
  const edges = [];

  function addNode(nodeId, options) {
    nodes.push({
      id: nodeId,
      width: NODE_WIDTH,
      ...options,
    });
  }

  function addEdge(source, target, options) {
    edges.push({
      id: `e_${source}_${target}`,
      source: source.toString(),
      target: target.toString(),
      type: editable ? "insertable" : "normal",
      markerEnd: {
        type: MarkerType.Arrow,
      },
      ...options,
    });
  }

  // function to add the new action node at the end of a branch
  // id: `newAction_${action.id}` or `newAction_trigger`
  function addNewActionNode(parentNodeId = null, data = {}) {
    if (!editable) return;

    const newActionId = `newAction_${parentNodeId}`;
    if (parentNodeId) addEdge(parentNodeId, newActionId, { type: "normal" });

    addNode(newActionId, {
      height:
        nodeDimensions[newActionId]?.height || actionNodeHeight("newAction"),
      width: nodeDimensions[newActionId]?.width || NODE_WIDTH,
      type: "newAction",
      data,
    });
  }

  // Custom functions for specific action types
  const customChildren = {
    ask_question: (action) => {
      const parentId = action.id.toString();

      // option branches
      action.options.ask_question_options?.forEach((option, index) => {
        if (!option.title) return;
        addBranchNode(parentId, `option_${index}`, "branch_option", {
          label: "automation.scenarios.actions.ask_question.when_reply",
          message: option.title,
        });
      });

      // expiration branch with action data
      addBranchNode(parentId, "option_expired", "branch_expiration", {
        label: "automation.scenarios.actions.ask_question.no_replies",
        ...action,
      });
    },
    wait_for_reply: (action) => {
      const parentId = action.id.toString();

      // check if action has keywords option
      if (action.options.keywords?.some(identity)) {
        action.options.keywords.forEach((keyword, index) => {
          addBranchNode(parentId, `option_replied_${index}`, "branch_option", {
            label:
              index == 0
                ? "automation.scenarios.actions.wait_for_reply.if_message_contains"
                : "automation.scenarios.actions.wait_for_reply.else_if_message_contains",
            message: keyword,
          });
        });
        addBranchNode(parentId, "option_replied", "branch_option", {
          label: "shared.else",
        });
      } else {
        // if no keywords
        addBranchNode(parentId, "option_replied", "branch_option", {
          label:
            "automation.scenarios.actions.wait_for_reply.when_contact_replies",
        });
      }

      // expiration branch with action data
      addBranchNode(parentId, "option_expired", "branch_expiration", {
        label: "automation.scenarios.actions.wait_for_reply.no_reply",
        ...action,
      });
    },
    conversion_link: (action) => {
      const parentId = action.id.toString();

      // when replied branch
      addBranchNode(parentId, "option_clicked", "branch_option", {
        label: "automation.scenarios.actions.conversion_link.when_click",
      });

      // expiration branch with action data
      addBranchNode(parentId, "option_expired", "branch_expiration", {
        label: "automation.scenarios.actions.conversion_link.no_click",
        ...action,
      });
    },
    survey: (action) => {
      const parentId = action.id.toString();
      // branches only for questions type survey
      if (action.survey?.survey_type == "questions") {
        // when filled branch
        addBranchNode(parentId, "option_filled", "branch_option", {
          label: "automation.scenarios.actions.survey.when_filled",
        });

        // expiration branch with action data
        addBranchNode(parentId, "option_expired", "branch_expiration", {
          label: "automation.scenarios.actions.survey.no_fill",
          ...action,
        });
      } else {
        addChildren(parentId);
      }
    },
    calendly_link: (action) => {
      const parentId = action.id.toString();
      // branches for paid users only
      if (action.plan != "basic") {
        // when booked branch
        addBranchNode(parentId, "option_booked", "branch_option", {
          label: "automation.scenarios.actions.calendly_link.when_booked",
        });

        // expiration branch with action data
        addBranchNode(parentId, "option_expired", "branch_expiration", {
          label: "automation.scenarios.actions.calendly_link.no_book",
          ...action,
        });
      } else {
        addChildren(parentId);
      }
    },
    http_request: (action) => {
      const parentId = action.id.toString();
      // HTTP success branch
      addBranchNode(parentId, "option_success", "branch_option", {
        label: "automation.scenarios.actions.http_request.if_success",
      });

      // HTTP failure branch
      addBranchNode(parentId, "option_failure", "branch_option", {
        label: "automation.scenarios.actions.http_request.if_failure",
        ...action,
      });
    },
    scenario_condition: (action) => {
      const parentId = action.id.toString();

      ["option_true", "option_false"].forEach((branchId) => {
        addBranchNode(parentId, branchId, "branch_option", {
          label:
            "automation.scenarios.actions.scenario_condition.branches." +
            branchId,
        });
      });
    },
  };

  // recursive function to add the action nodes/edges then their children
  // id: action_id.toString()
  function addItem(action, parentNodeId = null) {
    if (!action) return;
    if (action && !action._destroy) {
      // create edge from previous node
      if (parentNodeId) addEdge(parentNodeId, action.id.toString());

      // create the action node
      addNode(action.id.toString(), {
        height: nodeDimensions[action.id]?.height || actionNodeHeight(action),
        width: nodeDimensions[action.id]?.width || NODE_WIDTH,
        type: action.action_type,
        data: { ...action },
      });

      // check if the action has a custom children function
      if (customChildren[action.action_type]) {
        customChildren[action.action_type](action);
      } else {
        addChildren(action.id.toString());
      }
    }
  }

  function addBranchNode(nodeId, branchId, type, data = {}) {
    const branchNodeId = `${nodeId}_${branchId}`;
    addEdge(nodeId, branchNodeId, {
      type: "normal",
    });
    addNode(branchNodeId, {
      height:
        nodeDimensions[branchNodeId]?.height ||
        actionNodeHeight({ action_type: type }),
      width: nodeDimensions[branchNodeId]?.width || NODE_WIDTH,
      type,
      data,
    });

    addChildren(nodeId, branchId);
  }

  function addChildren(parentId, parentBranch = null) {
    // get the children of the action on this specific branch
    const children = actions.filter(
      (a) =>
        a.parent_id?.toString() == parentId && a.parent_branch == parentBranch,
    );

    const parentNodeId = parentBranch
      ? `${parentId}_${parentBranch}`
      : parentId.toString();

    // if the action has children, call the recursive function on each child
    if (children?.length > 0) {
      children?.forEach((child) => addItem(child, parentNodeId));
    } else {
      // else add a new action node
      addNewActionNode(parentNodeId, {
        parent_id: parentId,
        parent_branch: parentBranch,
      });
    }
  }

  // Trigger node at the start (id: "trigger")
  if (hasTriggers) {
    addNode("trigger", {
      height: nodeDimensions["trigger"]?.height || triggerNodeHeight(triggers),
      width: nodeDimensions["trigger"]?.width || NODE_WIDTH,
      type: "trigger",
    });
  }

  // First action node
  const rootActions = actions.filter(
    (action) => !action.parent_id && !action._destroy,
  );
  if (rootActions.length > 0) {
    rootActions.forEach((firstAction) =>
      addItem(firstAction, hasTriggers ? "trigger" : null),
    );
  } else {
    addNewActionNode(hasTriggers ? "trigger" : null);
  }

  // Graph layout

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.alignment": "LEFT",
      "elk.direction": "DOWN",
      "elk.spacing.nodeNode": 104,
      "elk.layered.spacing.nodeNodeBetweenLayers": 80,
      "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES",
    },
    children: nodes.map((node) => ({
      ...node,
      targetPosition: "top",
      sourcePosition: "bottom",
    })),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const graphResult = await elk.layout(graph);

  const graphNodes = nodes.map((node) => {
    const graphNode = graphResult.children.find(
      (child) => child.id === node.id,
    );

    return {
      ...node,
      height: null,
      position: {
        x: graphNode.x,
        y: graphNode.y,
      },
    };
  });

  return {
    nodes: graphNodes,
    edges,
  };
}
