import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ReactFlow,
  Background,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import generateNodesAndEdges from "../generateNodesAndEdges";
import resultNodeTypes from "./resultNodeTypes";
import NormalEdge from "../NormalEdge";
import OptionEdge from "../OptionEdge";
import { FlowContext } from "../../../../contexts/flow-context";

export default function ResultsFlow(props) {
  const { hasTriggers = false, triggers, actions = [], children } = props;

  // Flow
  const { fitView } = useReactFlow();

  // Nodes
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [nodeDimensions, setNodeDimensions] = useState({});
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  useEffect(async () => {
    const { nodes, edges } = await generateNodesAndEdges(
      hasTriggers,
      actions,
      triggers,
      nodeDimensions,
      false, // editable is false for results
    );
    setNodes(nodes);
    setEdges(edges);
  }, [hasTriggers, actions, triggers, nodeDimensions]);

  // Node types with a function to set their height from the child node
  const nodeTypesWithDimensions = useMemo(
    () =>
      Object.entries(resultNodeTypes).reduce(
        (acc, [nodeType, NodeComponent]) => {
          acc[nodeType] = (props) => (
            <NodeComponent
              {...props}
              nodeId={props.id}
              setNodeDimensions={setNodeDimensions}
            />
          );
          return acc;
        },
        {},
      ),
    [resultNodeTypes, setNodeDimensions],
  );

  const edgeTypes = useMemo(
    () => ({
      normal: NormalEdge,
      insertable: NormalEdge,
      option: OptionEdge,
    }),
    [],
  );

  // Fit view
  const fixedFitView = useCallback(() => {
    fitView({ minZoom: 1, maxZoom: 1 });
  }, [fitView]);

  useEffect(() => {
    window.addEventListener("resize", fixedFitView);
    return () => window.removeEventListener("resize", fixedFitView);
  }, [fitView]);

  const contextValues = {
    hasTriggers,
    triggers,
    actions,
  };

  return (
    <FlowContext.Provider value={contextValues}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodesDraggable={false}
        // panOnDrag={false}
        edgesFocusable={false}
        selectionMode="partial"
        panOnScroll={true}
        panOnScrollMode="free"
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        nodeTypes={nodeTypesWithDimensions}
        edgeTypes={edgeTypes}
        connectionRadius={0}
        fitView
        fitViewOptions={{
          minZoom: 1,
          maxZoom: 1,
        }}
      >
        <Background color="#999" variant="dots" />
        {children}
      </ReactFlow>
    </FlowContext.Provider>
  );
}
