import React, { useContext } from "react";
import { FlowContext } from "~/contexts/flow-context";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";
import robotIconBlack from "../../../../../assets/images/robot_icon_black.png";
import Input from "~/components/elements/Input";
import Notice from "../../../shared/Notice";

export default function AgentMessageNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const { agent_task } = options;

  const { editAction } = useContext(FlowContext);

  return (
    <>
      <ActionNode {...props}>
        <div
          className={`w-[320px] py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
            selected ? "ring-opacity-100 z-50" : null
          }`}
        >
          <div className="space-y-1">
            <div className="flex space-x-1">
              <img src={robotIconBlack} className="w-5 h-5" />
              <div className="text-md font-medium">
                {t("automation.scenarios.actions.agent_message.title")}
              </div>
            </div>
            <div className="text-2sm text-darker-gray">
              {t("automation.scenarios.actions.agent_message.description")}
            </div>
          </div>
          <Input
            type="textarea"
            expanding
            rows={3}
            placeholder={t(
              "automation.scenarios.actions.agent_message.task_placeholder",
            )}
            value={agent_task}
            onChange={(value) =>
              editAction(id, { options: { agent_task: value } })
            }
            insertVariables
            maxLength={480}
          />
          <Notice
            text={t("automation.scenarios.actions.agent_message.task_notice")}
          />
        </div>
      </ActionNode>
    </>
  );
}
