import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import React from "react";

export default function Notice(props) {
  const { text, icon: Icon = QuestionMarkCircleIcon } = props;

  return (
    <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm leading-4">
      <Icon className="w-4 inline-block mr-1" />
      {text}
    </div>
  );
}
