import React, { useContext, useState } from "react";
import { FlowContext } from "~/contexts/flow-context";
import { useTranslation } from "react-i18next";
import { ExclamationTriangleIcon, PencilIcon } from "@heroicons/react/20/solid";
import Button from "../../../elements/Button";
import IcebreakersModal from "../../../icebreakers/IcebreakersModal";

export default function IcebreakerOptions(props) {
  const { t } = useTranslation();

  const { trigger } = props;
  const { options } = trigger;

  const { editTriggerOptions } = useContext(FlowContext);

  const [icebreakerModal, setIcebreakerModal] = useState(false);

  const handlePick = (icebreaker) => {
    editTriggerOptions(trigger.id, {
      icebreaker_payload: icebreaker.payload,
      icebreaker_title: icebreaker.question,
    });
  };

  return (
    <div className="flex flex-col space-y-3">
      {options["icebreaker_payload"] && options["icebreaker_title"] ? (
        <div className="flex space-x-2 items-center">
          <div className="text-message icebreaker">
            {options["icebreaker_title"]}
          </div>
          <Button
            icon={PencilIcon}
            onClick={() => setIcebreakerModal(true)}
            size="small"
          />
        </div>
      ) : (
        <Button
          label={t(
            "automation.scenarios.trigger_type.icebreaker.select_icebreaker",
          )}
          onClick={() => setIcebreakerModal(true)}
        />
      )}
      <div className="bg-lighter-gray px-3 py-2 rounded-lg text-2sm">
        <ExclamationTriangleIcon className="w-4 inline-block mr-1" />
        {t("automation.scenarios.trigger_type.icebreaker.notice")}{" "}
        <a
          href="https://developers.facebook.com/docs/messenger-platform/instagram/features/ice-breakers/"
          className="text-primary underline font-semibold"
          target="_blank"
        >
          {t("shared.learn_more")}
        </a>
      </div>
      {icebreakerModal && (
        <IcebreakersModal
          onClose={() => setIcebreakerModal(false)}
          onPick={handlePick}
        />
      )}
    </div>
  );
}
