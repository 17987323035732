import React, { useContext, useEffect, useMemo, useState } from "react";
import { ContactsContext } from "../../contexts/contacts-context";
import ContactHeader from "./ContactHeader";
import EditContactFolders from "../inbox/folders/EditContactFolders";
import Input from "../elements/Input";
import { useTranslation } from "react-i18next";
import Button from "../elements/Button";
import { delay, isEqual } from "lodash";
import InputSelect from "../elements/InputSelect";
import ContactStatus from "./ContactStatus";
import {
  DocumentTextIcon,
  IdentificationIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Tabs from "../elements/Tabs";
import ContactNotes from "./ContactNotes";

export default function EditContact(props) {
  const { t } = useTranslation();

  const { contactId, onClose } = props;

  const {
    languages,
    loadLanguages,
    contact,
    setContact,
    loadContact,
    updateContact,
    contactProperties,
  } = useContext(ContactsContext);

  const [saving, setSaving] = useState(false);
  const [propertyValues, setPropertyValues] = useState([]);

  useEffect(async () => {
    const res = await loadContact(contactId);
    setContact(res);
    setDirty(false);
  }, [contactId]);

  const handleChange = (key) => (value) => {
    setDirty(true);
    setContact((prev) => ({ ...prev, [key]: value }));
  };

  // Properties

  useEffect(() => {
    setPropertyValues(
      contactProperties.map((prop) => {
        const propertyValue = contact?.properties?.find(
          (p) => p.contact_property_id === prop.id,
        );
        return {
          contact_property_id: prop.id,
          value: propertyValue?.value,
          id: propertyValue?.id,
        };
      }),
    );
  }, [contact?.properties, contactProperties]);

  const handleChangePropertyValue = (propertyId) => (value) => {
    setDirty(true);
    setPropertyValues((prev) =>
      prev.map((propValue) =>
        propValue.contact_property_id === propertyId
          ? { ...propValue, value }
          : propValue,
      ),
    );
  };

  // Save

  const [dirty, setDirty] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    const payload = {
      ...contact,
      contact_property_values_attributes: propertyValues,
    };
    const res = await updateContact(payload);
    setContact(res);
    setDirty(false);
    delay(() => setSaving(false), 300);
  };

  const languageOptions = [
    { value: null, label: t("contacts.no_language") },
    ...languages.map((language) => ({
      value: language,
      label: t("languages." + language),
    })),
  ];

  useEffect(loadLanguages, []);

  const [activeTab, setActiveTab] = useState("properties");
  const tabs = [
    {
      id: "properties",
      icon: IdentificationIcon,
    },
    {
      id: "notes",
      icon: DocumentTextIcon,
    },
  ].map((tab) => ({
    ...tab,
    active: activeTab == tab.id,
    title: t("contacts.tabs." + tab.id),
    onClick: () => setActiveTab(tab.id),
  }));

  if (!contact) return null;

  return (
    <div className="w-[412px] h-full flex flex-col overflow-hidden">
      <div className=" p-3 border-b flex justify-between">
        <div className="flex justify-between items-start space-x-2">
          <ContactHeader contact={contact} loading={!contact} />
          <ContactStatus contact={contact} />
        </div>
        <Button icon={XMarkIcon} onClick={onClose} />
      </div>
      <Tabs tabs={tabs} />
      {activeTab == "properties" && (
        <div className="p-3 pb-6 space-y-3 flex-1 overflow-auto">
          <Input
            label={t("inbox.details.email")}
            placeholder={t("inbox.details.email_placeholder")}
            size="small"
            value={contact?.email}
            onChange={handleChange("email")}
          />
          <Input
            label={t("inbox.details.phone")}
            placeholder={t("inbox.details.phone_placeholder")}
            size="small"
            value={contact?.phone}
            onChange={handleChange("phone")}
          />
          <InputSelect
            label={t("contacts.columns.language")}
            value={contact?.language}
            onChange={handleChange("language")}
            options={languageOptions}
          />
          {contactProperties.map((prop) => (
            <Input
              key={prop.id}
              label={prop.title}
              value={
                propertyValues.find((p) => p.contact_property_id == prop.id)
                  ?.value
              }
              onChange={handleChangePropertyValue(prop.id)}
              type={prop.property_type}
            />
          ))}
        </div>
      )}
      {activeTab == "notes" && (
        <ContactNotes value={contact?.notes} onChange={handleChange("notes")} />
      )}
      <div className="border-t p-3 flex-shrink-0 flex justify-start">
        <Button
          label={t("contacts.save")}
          onClick={handleSave}
          style="primary"
          loading={saving}
          disabled={!dirty}
        />
      </div>
    </div>
  );
}
