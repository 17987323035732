import React, { useState, useContext, useEffect, useRef } from "react";
import { Handle, Position } from "@xyflow/react";
import { useTranslation } from "react-i18next";
import ContactsDrawer from "~/components/contacts/ContactsDrawer";
import classNames from "~/utils/classNames";

export default function ResultsNode(props) {
  const { t } = useTranslation();

  const {
    nodeId,
    setNodeDimensions = () => {},
    data = {},
    first = false,
    last = false,
    children,
    noMetrics,
    width = 280,
    icon: Icon,
  } = props;

  const { content, options } = data;

  const nodeRef = useRef(null);

  useEffect(() => {
    if (nodeRef.current) {
      setNodeDimensions((prevHeights) => ({
        ...prevHeights,
        [nodeId]: {
          width: nodeRef.current.offsetWidth,
          height: nodeRef.current.offsetHeight,
        },
      }));
    }
  }, [nodeRef.current, nodeId, setNodeDimensions, content, options]);

  const [contactsDrawer, setContactsDrawer] = useState(null);
  const handleClickMetric = (metric) => {
    setContactsDrawer({
      params: {
        action_results: {
          id: data.id,
          metric,
        },
      },
    });
  };

  return (
    <>
      {first ? null : (
        <Handle
          type="target"
          position={Position.Top}
          className="opacity-0"
          isConnectable={false}
        />
      )}
      <div
        className="flex flex-col items-center space-y-3 group"
        style={{ width: `${width}px` }}
        ref={nodeRef}
      >
        {Icon && (
          <Icon className="w-6 absolute -left-10 top-1/2 -translate-y-1/2" />
        )}
        {children}
        {data?.metrics && !noMetrics && (
          <div className="bg-white rounded-lg overflow-hidden border flex divide-x nodrag nopan">
            {Object.keys(data.metrics).map((metric) => {
              if (data.metrics[metric] == null) return null;
              return (
                <div
                  key={metric}
                  className={classNames(
                    "px-3 py-2 w-24 space-y-0.5",
                    data.metrics[metric] > 0 &&
                      "hover:bg-neutral-50 transition-colors cursor-pointer",
                  )}
                  onClick={() =>
                    data.metrics[metric] > 0 && handleClickMetric(metric)
                  }
                >
                  <div className="text-xs font-medium leading-6 text-neutral-500">
                    {t("campaigns.metrics." + metric)}
                  </div>
                  <div className="text-base font-medium leading-6 text-neutral-900">
                    {data.metrics[metric]}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {last ? null : (
        <Handle
          type="source"
          position={Position.Bottom}
          className="opacity-0"
          isConnectable={false}
        />
      )}
      {contactsDrawer && (
        <ContactsDrawer
          {...contactsDrawer}
          onClose={() => setContactsDrawer(null)}
        />
      )}
    </>
  );
}
