import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import { FlowContext } from "~/contexts/flow-context";
import Button from "~/components/elements/Button";
import { LinkIcon, TrashIcon } from "@heroicons/react/20/solid";
import InputSelect from "~/components/elements/InputSelect";
import ResultsNode from "./ResultsNode";

export default function HttpRequestResultsNode(props) {
  const { t } = useTranslation();

  const { selected, data } = props;
  const { id, options } = data;

  const {
    http_method,
    http_url,
    http_headers = [],
    http_params = [],
  } = options;

  const { editAction } = useContext(FlowContext);

  const handleActionChange = (key, value) => {
    editAction(id, {
      options: { ...options, [key]: value },
    });
  };

  return (
    <ResultsNode
      {...props}
      help={
        "https://help.inro.social/en/articles/9420527-how-to-send-an-http-request-from-a-scenario"
      }
    >
      <div
        className={`p-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-2 ${
          selected ? "ring-opacity-100 z-50" : null
        }`}
      >
        <div className="text-md font-medium">
          {t("automation.scenarios.actions.http_request.title")}
        </div>
        <div className="text-2sm text-darker-gray">
          {t("automation.scenarios.actions.http_request.description")}
        </div>
        <div className="flex text-sm space-x-1">
          <div className="py-1 px-1.5 rounded bg-neutral-100 font-medium">
            {http_method}
          </div>
          <div className="py-1 px-1.5 rounded bg-neutral-100 flex-grow">
            {http_url}
          </div>
        </div>
        {/* Headers */}
        {http_headers?.length > 0 && (
          <div className="text-2sm font-medium">
            {t("automation.scenarios.actions.http_request.headers")}
          </div>
        )}
        {http_headers?.map((header, index) => (
          <div className="flex text-sm space-x-1">
            <div className="py-1 px-1.5 rounded bg-neutral-100 font-medium">
              {header.key}
            </div>
            <div className="py-1 px-1.5 rounded bg-neutral-100 flex-grow">
              {header.value}
            </div>
          </div>
        ))}
        {/* Body */}
        {http_params?.length > 0 && (
          <div className="text-2sm font-medium mt-2">
            {t("automation.scenarios.actions.http_request.body")}
          </div>
        )}
        {http_params?.map((header, index) => (
          <div className="flex text-sm space-x-1">
            <div className="py-1 px-1.5 rounded bg-neutral-100 font-medium">
              {header.key}
            </div>
            <div className="py-1 px-1.5 rounded bg-neutral-100 flex-grow">
              {header.value}
            </div>
          </div>
        ))}
      </div>
    </ResultsNode>
  );
}
