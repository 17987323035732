import React, { useState, useEffect, StrictMode } from "react";

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

import { LoadingContext } from "~/contexts/loading-context";

import UserProvider from "../providers/UserProvider";
import InboxProvider from "../providers/InboxProvider";

import Loading from "./shared/Loading";
import MainRouter from "./navigation/MainRouter";
import ErrorPage from "./utils/ErrorPage";
import UIProvider from "../providers/UIProvider";
import CalendarProvider from "../providers/CalendarProvider";
import PaymentsProvider from "../providers/PaymentsProvider";
import ShopifyProvider from "../providers/ShopifyProvider";
import AutomationProvider from "../providers/AutomationProvider";
import ContentProvider from "../providers/ContentProvider";
import ContactsProvider from "../providers/ContactsProvider";
import CampaignsProvider from "../providers/CampaignsProvider";
import DirtyProvider from "../providers/DirtyProvider";
import MediaFilesProvider from "../providers/MediaFilesProvider";
import AgentProvider from "../providers/AgentProvider";
import OnboardingScreen from "./onboarding/OnboardingScreen";
import SubscriptionProvider from "../providers/SubscriptionProvider";
import { reverse } from "lodash";

const providers = [
  SubscriptionProvider,
  ContentProvider,
  AutomationProvider,
  InboxProvider,
  MediaFilesProvider,
  ContactsProvider,
  CampaignsProvider,
  PaymentsProvider,
  CalendarProvider,
  ShopifyProvider,
  AgentProvider,
];

const honeybadger = Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.RAILS_ENV,
  revision: process.env.GIT_COMMIT || "master",
  debug: true,
});

export default function App(props) {
  const { user, organization } = props;

  // Loading
  const [loading, setLoading] = useState(false);
  const loadingValues = {
    loading,
    setLoading,
  };

  // Honeybadger && Mixpanel
  useEffect(() => {
    if (!user) return;
    honeybadger.setContext({
      user_id: user.id,
      user_email: user.email,
    });
  }, [user]);

  return (
    <HoneybadgerErrorBoundary
      honeybadger={honeybadger}
      ErrorComponent={ErrorPage}
    >
      <LoadingContext.Provider value={loadingValues}>
        <UIProvider>
          <DirtyProvider>
            <UserProvider user={user} organization={organization}>
              {reverse(providers).reduce(
                (acc, Provider) => {
                  return (
                    <Provider organizationId={organization.id}>{acc}</Provider>
                  );
                },
                <MainRouter />,
              )}
            </UserProvider>
          </DirtyProvider>
        </UIProvider>
        {loading && <Loading text={loading} />}
      </LoadingContext.Provider>
    </HoneybadgerErrorBoundary>
  );
}
