import { compact } from "lodash";
import React, { useContext } from "react";
import {
  ChatBubbleBottomCenterTextIcon,
  ArrowUturnLeftIcon,
  AtSymbolIcon,
  EnvelopeIcon,
  LinkIcon,
  FolderPlusIcon,
  SignalIcon,
  VideoCameraIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import Modal from "~/components/shared/Modal";
import { UserContext } from "~/contexts/user-context";
import triggerTypeOptions from "./triggerTypeOptions";
import { CodeBracketIcon } from "@heroicons/react/24/solid";
import { SubscriptionContext } from "../../../../contexts/subscription-context";
import ProTag from "../../../shared/ProTag";

export default function TriggerTypeMenu(props) {
  const { t } = useTranslation();
  const { onSubmit, onClose, selectedTrigger } = props;

  const { organization, user } = useContext(UserContext);
  const { isPro, limits, limitedFeatureAlert } =
    useContext(SubscriptionContext);

  const direct_messages = [
    {
      type: "messages",
      icon: EnvelopeIcon,
    },
    {
      type: "outgoing_message",
      icon: EnvelopeIcon,
    },
    {
      type: "story_reply",
      icon: ArrowUturnLeftIcon,
    },
    {
      type: "referral_ad",
      icon: ArrowUturnLeftIcon,
    },
    {
      type: "icebreaker",
      icon: EnvelopeIcon,
    },
    // {
    //   type: "referral",
    //   icon: LinkIcon,
    // },
  ];

  const mentions = [
    {
      type: "story_mention",
      icon: AtSymbolIcon,
    },
    {
      type: "mention_media",
      icon: AtSymbolIcon,
    },
  ];

  const comments = [
    {
      type: "comment",
      icon: ChatBubbleBottomCenterTextIcon,
    },
    {
      type: "live_comment",
      icon: VideoCameraIcon,
      pro: true,
    },
    // {
    //   type: "mention_comment",
    //   icon: AtSymbolIcon,
    // },
  ];

  const others = [
    {
      type: "folder",
      icon: FolderPlusIcon,
    },
    {
      type: "webhook",
      icon: CodeBracketIcon,
      pro: true,
    },
  ];

  const triggerGroups = [
    {
      title: t("automation.scenarios.trigger_type.categories.direct_messages"),
      triggers: direct_messages,
    },
    {
      title: t("automation.scenarios.trigger_type.categories.mentions"),
      triggers: mentions,
    },
    {
      title: t("automation.scenarios.trigger_type.categories.comments"),
      triggers: comments,
    },
    {
      title: t("automation.scenarios.trigger_type.categories.others"),
      triggers: others,
    },
  ];

  const renderTrigger = (trigger) => {
    const Icon = trigger.icon;
    return (
      <button
        key={trigger.type}
        className={`w-full border-2 border-black border-opacity-20 hover:border-opacity-40 rounded-lg flex items-center space-x-3 px-3 py-2.5 transition-all disabled:opacity-30 disabled:pointer-events-none ${
          selectedTrigger === trigger.type ? "bg-black text-white" : ""
        }`}
        onClick={() => handleClickTrigger(trigger.type)}
      >
        {Icon && <Icon className="w-4 flex-shrink-0" />}
        <div className="font-medium text-left flex-grow">
          {t(`automation.scenarios.trigger_type.${trigger.type}.label`)}
        </div>
        {trigger.pro && !isPro && <ProTag />}
      </button>
    );
  };

  const handleClickTrigger = (triggerType) => {
    if (limits.features?.includes(`trigger_${triggerType}`)) {
      limitedFeatureAlert();
      return;
    }
    const options = triggerTypeOptions[triggerType]?.defaultOptions || {};
    onSubmit({ trigger_type: triggerType, options });
    onClose();
  };

  return (
    <Modal onClose={onClose} className="max-w-xl">
      <div className="flex flex-col space-y-4 p-3 pb-6 sm:p-4">
        {triggerGroups.map((group) =>
          compact(group.triggers).length > 0 ? (
            <div key={group.title}>
              <div className="font-medium text-md mb-3">{group.title}</div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                {compact(group.triggers).map(renderTrigger)}
              </div>
            </div>
          ) : null,
        )}
      </div>
    </Modal>
  );
}
