import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import classNames from "~/utils/classNames";

export default function Checkbox({ id, name, checked, ...passthroughProps }) {
  return (
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      className={classNames(
        "mt-0.5 h-5 w-5 sm:h-4 sm:w-4 rounded border-gray-300 text-primary pointer-events-none",
        "focus:ring-primary focus:ring-2 focus:ring-offset-2",
      )}
      {...passthroughProps}
    />
  );
}
