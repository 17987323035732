import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  QuestionMarkCircleIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import { AgentContext } from "~/contexts/agent-context";
import classNames from "~/utils/classNames";
import { DateTime } from "luxon";
import AgentChat from "./AgentChat";
import { isEqual } from "lodash";

export default function AgentTraining() {
  const { t } = useTranslation();

  const {
    loading: training,
    agent: initialAgent,
    updateAgent,
  } = useContext(AgentContext);
  const [agent, setAgent] = useState(initialAgent || {});

  useEffect(() => setAgent(initialAgent), [initialAgent]);

  const hasChanged = useMemo(
    () => !isEqual(agent, initialAgent),
    [agent, initialAgent],
  );

  return (
    <div className="flex flex-col-reverse md:flex-row gap-6 h-full">
      <div className="flex-grow flex flex-col md:overflow-hidden space-y-4">
        <div className="bg-yellow-100 text-yellow-800 flex items-center space-x-2 rounded-lg p-3">
          <QuestionMarkCircleIcon className="h-5" />
          <div className="text-sm">{t("agent.training.description")}</div>
        </div>
        <Input
          type="textarea"
          label={t("agent.training.data")}
          placeholder={t("agent.training.data_placeholder")}
          className="flex-grow"
          inputClassName="h-full"
          onChange={(value) => setAgent({ ...agent, data: value })}
          value={agent?.data}
          maxLength={5000}
        />
      </div>
      <div className="md:w-72 flex-shrink-0 flex-grow-0 flex flex-col space-y-4">
        <div className="py-2 text-sm text-darker-gray">
          <span
            className={classNames(
              "inline-block w-2 h-2 rounded-full mr-2",
              training
                ? "bg-yellow-500"
                : hasChanged
                  ? "bg-red-500"
                  : "bg-green-500",
            )}
          />
          {training
            ? t("agent.training.training_in_progress")
            : hasChanged
              ? t("agent.training.data_changed")
              : t("agent.training.data_unchanged")}
        </div>
        <Button
          label={t("agent.training.train")}
          onClick={() => updateAgent(agent)}
          icon={SparklesIcon}
          disabled={!hasChanged}
          loading={training}
          style="primary"
          className="w-full"
        />
        {hasChanged && (
          <Button
            label={t("shared.cancel_changes")}
            onClick={() => setAgent(initialAgent)}
          />
        )}
        <div className="text-center py-2 text-sm text-dark-gray">
          {t("agent.training.last_trained", {
            date: DateTime.fromISO(initialAgent?.updated_at).toLocaleString(
              DateTime.DATETIME_MED,
            ),
          })}
        </div>
        <AgentChat />
      </div>
    </div>
  );
}
