import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";
import Textarea from "react-expanding-textarea";
import { useTranslation } from "react-i18next";

import { UserContext } from "~/contexts/user-context";
import { CalendarContext } from "~/contexts/calendar-context";
import { UIContext } from "~/contexts/ui-context";

import {
  CalendarIcon,
  CurrencyEuroIcon,
  PhotoIcon,
  MicrophoneIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";

import { appInset } from "~/utils/environment";
import formDataHeaders from "~/utils/formDataHeaders";
import Button from "~/components/elements/Button";
import AppointmentModal from "../appointments/AppointmentModal";
import PaymentModal from "../payments/PaymentModal";
import { PaymentsContext } from "~/contexts/payments-context";
import { AutomationContext } from "~/contexts/automation-context";
import { LinkIcon } from "@heroicons/react/20/solid";
import { MAX_MESSAGE_BYTE_SIZE } from "../../constants";
import AudioMessageModal from "./AudioMessageModal";
import { MediaFilesContext } from "~/contexts/media-files-context";
import { unionBy } from "lodash";

export default function MessageForm(props) {
  const { conversationId, onSend, contact, inputRef, setMessages } = props;

  const { t } = useTranslation();

  const { keyboardHeight } = useContext(UIContext);
  const { organization } = useContext(UserContext);
  const { createAppointment } = useContext(CalendarContext);
  const { createPayment } = useContext(PaymentsContext);
  const { conversionLinks, showSendConversionLinkMenu, showRunScenarioMenu } =
    useContext(AutomationContext);

  const formRef = useRef(null);
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("");
  const { createMedia } = useContext(MediaFilesContext);

  const textByteSize = new Blob([message]).size;

  const handleTextChange = (evt) => {
    const newByteSize = new Blob([evt.target.value]).size;
    if (newByteSize > MAX_MESSAGE_BYTE_SIZE) return;
    setMessage(evt.target.value);
  };

  const handleTextKeyDown = (evt) => {
    // return key on desktop sends message
    if (evt.key == "Enter" && isDesktop && !evt.shiftKey) {
      evt.preventDefault();
      handleSubmit();
      return;
    }

    // no line return if empty
    if (evt.key == "Enter" && !evt.isShift && message.length <= 0) {
      evt.preventDefault();
      return;
    }
  };

  const handleSubmit = (evt) => {
    if (evt) evt.preventDefault();
    if (message.length <= 0) return;

    const messagePayload = {
      conversation_id: conversationId,
      content: message,
      received: false,
      pending: true,
    };

    // scroll to bottom
    onSend();

    submitMessageForm();

    setMessage("");

    inputRef.current.focus();
  };

  // Photo/video/audio attachments

  const [audioMessageModalOpen, setAudioMessageModalOpen] = useState(false);

  const handleImageChange = async () => {
    const input = fileInputRef.current;

    if (input.files.length == 0) {
      input.value = null;
      return;
    }

    const file = input.files.item(0);
    const size = Math.round(file.size / 1024);

    if (size > 8192) {
      window.alert(t("inbox.conversation.max_file_size." + fileType));
      input.value = null;
      return;
    }

    const fileType = file.type.split("/")[0];

    if (!["image", "video", "audio"].includes(fileType)) {
      window.alert(t("inbox.conversation.invalid_file_type"));
      input.value = null;
      return;
    }

    const media = await createMedia(file, fileType);
    submitMessageForm(media);

    input.value = null;
  };

  // Shared function to submit the form
  const submitMessageForm = async (media = null) => {
    const formData = new FormData(formRef.current);

    formData.append("conversation_ids", [conversationId]);
    formData.append("received", false);

    if (media) {
      formData.append("media_file_id", media.id);
      formData.append("message_type", media.media_type);
    } else {
      formData.append("message_type", "text");
    }

    const res = await axios.post(
      "/api/inbox/messages",
      formData,
      formDataHeaders(),
    );
    setMessages((messages) => unionBy(messages, res.data, "id"));
  };

  // Calendar events
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);

  const sendAppointment = async (calendar_events, inputAmount) => {
    await createAppointment(conversationId, calendar_events, inputAmount);

    // scroll to bottom
    onSend(true);
  };

  // Payment link
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const sendPaymentLink = async (inputAmount) => {
    const payment = await createPayment(conversationId, inputAmount);

    // scroll to bottom
    onSend(true);
  };

  return (
    <div
      className={`overflow-hidden flex-shrink-0 flex flex-col bg-white pt-2 transition-all duration-250 ease-in-out`}
      style={{
        paddingBottom:
          keyboardHeight > 0 ? keyboardHeight + 16 : appInset.bottom + 16,
      }}
    >
      <div className="w-full flex items-center flex-wrap gap-3 px-4 mb-3 scrollbar-hide">
        {/* Run scenario */}
        <Button
          label={t("inbox.conversation.run_scenario")}
          size="small"
          icon={BoltIcon}
          onClick={() =>
            showRunScenarioMenu({ conversation_id: conversationId })
          }
        />
        {/* Calendly conversion links */}
        {organization.features.calendar &&
          conversionLinks.filter((l) => l.link_provider == "calendly").length >
            0 && (
            <Button
              label={t("automation.calendly_links.send")}
              size="small"
              icon={CalendarIcon}
              onClick={() =>
                showSendConversionLinkMenu(
                  [conversationId],
                  "calendly",
                  t("automation.calendly_links.send"),
                )
              }
            />
          )}
        {/* Other conversion links */}
        {conversionLinks.filter((l) => l.link_provider == "none").length >
          0 && (
          <Button
            label={t("automation.conversion_links.send")}
            size="small"
            icon={LinkIcon}
            onClick={() => showSendConversionLinkMenu([conversationId])}
          />
        )}
        {organization.features.payments && (
          <Button
            label={t("inbox.conversation.request_payment")}
            size="small"
            icon={CurrencyEuroIcon}
            onClick={() => setPaymentModalOpen(true)}
          />
        )}
        {organization.features.calendar && (
          <Button
            label={t("inbox.conversation.schedule_appointment")}
            size="small"
            icon={CalendarIcon}
            onClick={() => setAppointmentModalOpen(true)}
          />
        )}
      </div>
      <form ref={formRef} className="px-4">
        <div className="flex items-center w-full rounded-2.5xl bg-white border border-neutral-300 focus-within:ring-1">
          <label className="flex justify-between items-center py-2 pl-3 pr-0.5 flex-shrink-0 cursor-pointer">
            <PhotoIcon className="w-6 h-6 text-neutral-700" />
            <input
              type="file"
              accept="image/*,video/*"
              className="hidden"
              name="attachment_file"
              onChange={handleImageChange}
              ref={fileInputRef}
            />
          </label>
          <Textarea
            className="border-none bg-transparent focus:ring-0 outline-none py-2.5 pl-1.5 flex-grow [max-height:120px] resize-none leading-tight"
            placeholder={t("inbox.conversation.compose")}
            value={message}
            onChange={handleTextChange}
            onKeyDown={handleTextKeyDown}
            ref={inputRef}
            name="content"
          />

          {textByteSize > 0 && (
            <div className="text-dark-gray py-3 px-2 text-2sm">
              {textByteSize}/{MAX_MESSAGE_BYTE_SIZE}
            </div>
          )}
          {message.length > 0 ? (
            <button
              className="py-2 pl-2 pr-3 flex-shrink-0 font-medium text-primary"
              onClick={handleSubmit}
            >
              {t("inbox.conversation.send")}
            </button>
          ) : (
            <MicrophoneIcon
              onClick={() => setAudioMessageModalOpen(true)}
              className="w-6 h-6 text-neutral-700 cursor-pointer mx-3"
            />
          )}
        </div>
      </form>
      {paymentModalOpen && (
        <PaymentModal
          onClose={() => setPaymentModalOpen(false)}
          onConfirm={sendPaymentLink}
        />
      )}
      {appointmentModalOpen && (
        <AppointmentModal
          slotTitle={"@" + contact.username}
          onSubmit={sendAppointment}
          onClose={() => setAppointmentModalOpen(false)}
        />
      )}
      {audioMessageModalOpen && (
        <AudioMessageModal
          onClose={() => setAudioMessageModalOpen(false)}
          onCreate={(media_file_payload) =>
            submitMessageForm(media_file_payload)
          }
        />
      )}
    </div>
  );
}
