import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../contexts/user-context";
import Toggle from "../../shared/Toggle";
import Button from "../../elements/Button";
import OptOutModal from "./OptOutModal";
import { UIContext } from "../../../contexts/ui-context";

export default function OptOutModule() {
  const { t } = useTranslation();

  const { showPrompt } = useContext(UIContext);
  const { organization, updateOrganization } = useContext(UserContext);
  const { optout_options: options } = organization;

  const [settingsModal, setSettingsModal] = useState(false);

  const handleToggle = (value) => {
    const prompt = value
      ? t("opt_out.activate_prompt")
      : t("opt_out.deactivate_prompt");
    showPrompt(prompt, () => {
      updateOrganization({ optout_options: { ...options, active: value } });
    });
  };

  return (
    <div className="border p-3 rounded-xl flex justify-between items-stretch space-x-3 max-w-xs">
      <div className="space-y-2">
        <div className="text-sm">{t("opt_out.title")}</div>
        <div className="text-xs text-neutral-600">
          {t("opt_out.description")}
        </div>
      </div>
      <div className="flex flex-col items-end justify-between space-y-3">
        <Toggle
          value={options.active}
          onChange={handleToggle}
          size="small"
          color="red"
        />
        <Button
          label={t("shared.settings")}
          onClick={() => setSettingsModal(true)}
          size="extra-small"
        />
      </div>
      {settingsModal && <OptOutModal onClose={() => setSettingsModal(false)} />}
    </div>
  );
}
