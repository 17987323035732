import React, { useContext, useEffect, useState, useCallback } from "react";
import axios from "axios";
import { CampaignsContext } from "~/contexts/campaigns-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import { useTranslation } from "react-i18next";
import headers from "~/utils/headers";
import { switchToOrganization } from "../api/organizations";

export default function CampaignsProvider(props) {
  const { t } = useTranslation();

  const { showPrompt, showMenu, handleError } = useContext(UIContext);
  const { user, organization } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignTemplates, setCampaignTemplates] = useState([]);

  const loadCampaigns = (params) => {
    setLoading(true);
    axios.get(`/api/campaigns/campaigns`, { params }).then((res) => {
      setCampaigns(res.data);
      setLoading(false);
    });
  };

  useEffect(loadCampaigns, []);

  const loadCampaign = async (campaignId) => {
    const res = await axios.get(
      `/api/campaigns/campaigns/${campaignId}`,
      headers(),
    );
    setCampaigns((campaigns) =>
      campaigns.map((c) => (c.id === campaignId ? res.data : c)),
    );
    return res.data;
  };

  const loadCampaignTemplates = useCallback(() => {
    setLoading(true);
    axios.get("/api/campaigns/templates").then((res) => {
      setCampaignTemplates(res.data);
      setLoading(false);
    });
  }, [setLoading, setCampaignTemplates]);

  useEffect(loadCampaignTemplates, [organization.id]);

  const testCampaign = async (campaign, contactId) => {
    if (!campaign.id) return;

    const res = await axios.post(
      `/api/campaigns/campaigns/${campaign.id}/send_test`,
      { ...campaign, contact_id: contactId },
      headers(),
    );
    setCampaigns((campaigns) =>
      campaigns.map((c) => (c.id === campaign.id ? res.data : c)),
    );
    return res.data;
  };

  const sendCampaign = async (campaign) => {
    if (!campaign.id) return;
    return await updateCampaign({ ...campaign, status: "running" });
  };

  const scheduleCampaign = async (campaign, date) => {
    if (!campaign.id) return;
    const payload = {
      ...campaign,
      status: "scheduled",
      scheduled_at: date,
    };
    return await updateCampaign(payload);
  };

  const saveCampaign = async (campaign) => {
    if (!campaign.id) return;
    return await updateCampaign({ ...campaign, status: "draft" });
  };

  const createCampaign = async (campaign) => {
    const res = await axios.post(
      `/api/campaigns/campaigns`,
      campaign,
      headers(),
    );
    setCampaigns((campaigns) => [...campaigns, res.data]);
    return res.data;
  };

  const updateCampaign = async (campaign) => {
    if (!campaign.id) return;

    try {
      const res = await axios.patch(
        `/api/campaigns/campaigns/${campaign.id}`,
        campaign,
        headers(),
      );
      setCampaigns((campaigns) =>
        campaigns.map((c) => (c.id === campaign.id ? res.data : c)),
      );
      return res.data;
    } catch (error) {
      handleError(error);
    }
  };

  const cancelCampaign = async (campaignId) => {
    showPrompt(t("campaigns.cancel_campaign_confirm"), async () => {
      const res = await axios.patch(
        `/api/campaigns/campaigns/${campaignId}/cancel`,
        {},
        headers(),
      );
      setCampaigns((campaigns) =>
        campaigns.map((c) => (c.id === campaignId ? res.data : c)),
      );
    });
  };

  const duplicateCampaignToOrganization = async (
    campaignId,
    organizationId = null,
  ) => {
    setLoading(true);
    const res = await axios.post(
      `/api/campaigns/campaigns/${campaignId}/duplicate`,
      { organization_id: organizationId },
      headers(),
    );
    if (organizationId === organization.id || !organizationId) {
      setCampaigns((campaigns) => [...campaigns, res.data]);
      setLoading(false);
    } else {
      switchToOrganization(organizationId, "/campaigns/" + res.data.id);
    }
  };

  const duplicateCampaign = async (campaignId) => {
    if (user.organization_users.length === 1) {
      return await duplicateCampaignToOrganization(
        campaignId,
        user.organization_users[0].organization.id,
      );
    } else {
      showMenu({
        title: t("automation.campaigns.duplicate_title"),
        actions: user.organization_users.map((ou) => ({
          label: (
            <div className="flex items-center space-x-2">
              <img
                className="w-5 h-5 rounded-full"
                src={ou.organization.profile_picture}
              />
              <div
                className={
                  ou.organization.id === organization.id ? "font-medium" : ""
                }
              >
                {ou.organization.name} - @{ou.organization.username}
              </div>
              {ou.organization.id === organization.id && (
                <div className="text-neutral-500">
                  {t("shared.same_organization")}
                </div>
              )}
            </div>
          ),
          action: async () => {
            duplicateCampaignToOrganization(campaignId, ou.organization.id);
          },
        })),
      });
    }
  };

  const resendLimited = async (campaignId) => {
    setLoading(true);
    const res = await axios.post(
      `/api/campaigns/campaigns/${campaignId}/resend_limited`,
      {},
      headers(),
    );
    setCampaigns((campaigns) =>
      campaigns.map((c) => (c.id === campaignId ? res.data : c)),
    );
    setLoading(false);
    return res.data;
  };

  const campaignContactsCount = async (params) => {
    const res = await axios.get(`/api/campaigns/campaigns/contacts`, {
      params,
    });
    return res.data;
  };

  const checkCampaignStatus = async (campaignId) => {
    const res = await axios.get(
      `/api/campaigns/campaigns/${campaignId}/check_status`,
      headers(),
    );
    setCampaigns((campaigns) =>
      campaigns.map((c) => (c.id === campaignId ? res.data : c)),
    );
    return res.data;
  };

  const deleteCampaign = (campaignId) => {
    showPrompt(t("campaigns.delete_campaign_confirm"), () => {
      setLoading(true);
      axios
        .delete(`/api/campaigns/campaigns/${campaignId}`, headers())
        .then((res) => {
          setCampaigns((campaigns) =>
            campaigns.filter((c) => c.id !== campaignId),
          );
          setLoading(false);
        });
    });
  };

  const loadCampaignExecutions = async (params) => {
    const res = await axios.get(`/api/campaigns/executions`, { params });
    return res.data;
  };

  const contextValues = {
    loading,
    campaigns,
    loadCampaigns,
    loadCampaign,
    campaignTemplates,
    loadCampaignTemplates,
    testCampaign,
    sendCampaign,
    scheduleCampaign,
    saveCampaign,
    createCampaign,
    cancelCampaign,
    duplicateCampaign,
    resendLimited,
    campaignContactsCount,
    checkCampaignStatus,
    deleteCampaign,
    loadCampaignExecutions,
  };

  return (
    <CampaignsContext.Provider value={contextValues}>
      {props.children}
    </CampaignsContext.Provider>
  );
}
