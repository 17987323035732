import React from "react";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";
import robotIconBlack from "../../../../../assets/images/robot_icon_black.png";

export default function AgentReplyNode(props) {
  const { t } = useTranslation();

  const { selected } = props;

  return (
    <>
      <ActionNode {...props}>
        <div
          className={`w-[280px] py-3 px-3 bg-white border-2 rounded-2xl nodrag ring-1 ring-indigo-400 ring-offset-1 ring-opacity-0 hover:ring-opacity-100 transition-all space-y-3 ${
            selected ? "ring-opacity-100 z-50" : null
          }`}
        >
          <div className="space-y-1">
            <div className="flex space-x-1">
              <img src={robotIconBlack} className="w-5 h-5" />
              <div className="text-md font-medium">
                {t("automation.scenarios.actions.agent_reply.title")}
              </div>
            </div>
            <div className="text-2sm text-darker-gray">
              {t("automation.scenarios.actions.agent_reply.description")}
            </div>
          </div>
        </div>
      </ActionNode>
    </>
  );
}
