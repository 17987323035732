import React, { useEffect, useRef, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { TrashIcon, StopCircleIcon } from "@heroicons/react/24/solid";
import { ArrowUpIcon } from "@heroicons/react/20/solid";
import Loader from "~/components/utils/Loader";
import axios from "axios";
import renderTime from "~/utils/renderTime";
import reactNativeMessage from "~/utils/reactNativeMessage";

export default function AudioRecordForm(props) {
  const { onCancel, onRecord } = props;

  const {
    status,
    error,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    previewAudioStream,
  } = useReactMediaRecorder({
    askPermissionOnMount: true,
  });

  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(0);
  const audioBarRef = useRef(null);
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const progressAudio = () => {
    if (!audioBarRef?.current) return;
    const currentWidth = audioBarRef.current.offsetWidth;
    audioBarRef.current.style.width = `${currentWidth + 1}px`;
    audioBarRef.current.parentElement.scroll(
      audioBarRef.current.parentElement.scrollWidth,
      0,
    );
    // Add 20ms to the time
    setTime((prevTime) => prevTime + 0.02);
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      startRecording();
      reactNativeMessage({ requestAudio: true });
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (status == "recording") {
      intervalRef.current = setInterval(progressAudio, 20);
    } else {
      clearInterval(intervalRef.current);
    }
  }, [status]);

  const handleStop = () => {
    clearInterval(intervalRef.current);
    stopRecording();
  };

  const handleSend = async () => {
    setLoading(true);
    const mediaBlobFile = await axios.get(mediaBlobUrl, {
      responseType: "blob",
    });
    const file = new File([mediaBlobFile.data], "audio.mp4");
    onRecord(file);
    setLoading(false);
  };

  const handleCancel = () => {
    clearTimeout(timeoutRef.current);
    stopRecording();
    clearBlobUrl();
    onCancel();
  };

  const renderStatusIcon = () => {
    if (status == "recording") {
      return (
        <StopCircleIcon className="w-6 flex-shrink-0" onClick={handleStop} />
      );
    } else if (status == "stopped") {
      return <ArrowUpIcon className="w-6 flex-shrink-0" onClick={handleSend} />;
    } else {
      return (
        <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
          <Loader stroke="#000" width={20} strokeWidth={8} />
        </div>
      );
    }
  };

  return (
    <div className="h-[42px] w-full rounded-full bg-primary flex items-center justify-between space-x-3 px-4 py-2">
      <TrashIcon className="w-5 flex-shrink-0" onClick={handleCancel} />
      <div className="flex-grow overflow-auto scrollbar-hide flex items-center justify-start">
        <div
          ref={audioBarRef}
          className="h-2 bg-black rounded-full flex-shrink-0"
        />
      </div>
      <div className="text-2sm">{renderTime(time)}</div>
      {loading ? (
        <div className="w-6 h-6 flex items-center justify-center flex-shrink-0">
          <Loader stroke="#000" width={20} strokeWidth={10} />
        </div>
      ) : (
        renderStatusIcon()
      )}
    </div>
  );
}
