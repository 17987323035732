import React, { useContext } from "react";
import { SubscriptionContext } from "../../contexts/subscription-context";
import { useTranslation } from "react-i18next";
import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import { useCopyToClipboard } from "react-use";
import { UIContext } from "../../contexts/ui-context";

export default function RedeemCodes() {
  const { t } = useTranslation();

  const [state, copyToClipboard] = useCopyToClipboard();

  const { redeemCodes } = useContext(SubscriptionContext);
  const { showTooltip } = useContext(UIContext);

  const handleCopy = (code) => {
    copyToClipboard(code);
    showTooltip(t("shared.copied"));
  };

  if (!redeemCodes) return null;

  return (
    <div className="space-y-2">
      <div className="text-sm font-medium">
        {t("subscription.redeem_codes.title")}
      </div>
      {redeemCodes.map((code) => (
        <div
          key={code.id}
          className="bg-lighter-gray p-3 rounded-xl flex items-start justify-between"
        >
          <div className="space-y-1">
            <div className="text-sm font-semibold">
              {code.stripe_product?.name}
            </div>
            <div
              className="bg-light-gray rounded-lg px-1.5 py-1 text-neutral-800 text-xs cursor-pointer"
              onClick={() => handleCopy(code.code)}
            >
              {code.code}
              <DocumentDuplicateIcon className="inline-block h-4 ml-1" />
            </div>
          </div>
          <div className="text-sm font-semibold">
            {code.redemptions}/{code.max_redemptions}
          </div>
        </div>
      ))}
    </div>
  );
}
