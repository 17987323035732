import React, { useState } from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { useTranslation } from "react-i18next";
import classNames from "../../../utils/classNames";
import SurveyQuestionInput from "./SurveyQuestionInput";
import ButtonGroup from "../../elements/ButtonGroup";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function QuestionsSurveyPreview(props) {
  const { t } = useTranslation();

  const { survey, questions, className = "" } = props;

  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <div
      className={classNames(
        "bg-black p-4 flex flex-col items-center justify-center",
        className,
      )}
    >
      <div className="flex flex-col space-y-4 max-w-xs w-full">
        <div className="bg-white rounded-lg p-4 space-y-2">
          <div className="font-medium">{survey?.title}</div>
          <div className="text-sm">{survey?.subtitle}</div>
        </div>
        {showSuccess ? (
          <div className="bg-white rounded-lg p-4 space-y-4">
            {questions.map((question) => (
              <div className="space-y-2" key={question.id}>
                <div className="font-medium text-headings">
                  {question.title}
                </div>
                <div className="text-sm text-muted">
                  <span className="italic">{t("shared.not_answered")}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="bg-white rounded-lg p-4 space-y-4 pointer-events-none">
            {questions.map((question, index) => (
              <SurveyQuestionInput key={index} question={question} />
            ))}
          </div>
        )}

        {showSuccess && (
          <div className="bg-white rounded-lg p-4 flex flex-col items-center space-y-2">
            <CheckCircleIcon className="h-12 w-12 text-green-500" />
            <div className="text-sm">
              {t("surveys.questions.success_message")}
            </div>
          </div>
        )}

        <Button
          className="w-full max-w-sm"
          style="primary"
          label={
            showSuccess
              ? t("surveys.questions.edit_replies")
              : t("surveys.questions.submit")
          }
          onClick={() => setShowSuccess(!showSuccess)}
        />
      </div>
    </div>
  );
}
