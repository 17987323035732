import axios from "axios";
import headers from "../utils/headers";

const loadOnboardingChecklist = async () => {
  const res = await axios.get(`/api/organization/onboarding_checklist`);
  return res.data;
};

const switchToOrganization = async (organizationId, path = null) => {
  await axios.patch(
    `/api/organization/switch`,
    {
      id: organizationId,
    },
    headers(),
  );
  if (path) {
    window.location.replace(path);
  } else {
    window.location.reload();
  }
};

export { loadOnboardingChecklist, switchToOrganization };
