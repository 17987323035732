import React from "react";
import { useTranslation } from "react-i18next";

export default function NoPage() {
  const { t } = useTranslation();

  return (
    <div className="space-y-2 p-4 rounded-lg bg-yellow-100">
      <h2 className="text-md font-semibold">
        {t("inbox.permissions.no_page.title")}
      </h2>
      <p className="text-sm">{t("inbox.permissions.no_page.text")}</p>
    </div>
  );
}
