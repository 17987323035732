import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getContacts } from "~/api/contacts/contacts";
import ContactPage from "~/components/contacts/ContactPage";
import FolderTag from "~/components/inbox/folders/FolderTag";
import SideDrawer from "~/components/shared/SideDrawer";
import { ContactsContext } from "~/contexts/contacts-context";
import { UIContext } from "~/contexts/ui-context";
import { UserContext } from "~/contexts/user-context";
import headers from "~/utils/headers";

export default function ContactsProvider(props) {
  const { t } = useTranslation();

  const { showDialog, showPrompt, showMenu } = useContext(UIContext);
  const { organization } = useContext(UserContext);

  const { organizationId } = props;

  const [loading, setLoading] = useState(false);

  // Stores any list of contacts that are visible on screen
  const [contacts, setContacts] = useState([]);

  const loadContacts = async (params = {}) => {
    setLoading(true);
    const res = await getContacts(params);
    setLoading(false);
    return res;
  };

  const updateContacts = async (contacts) => {
    const res = await axios.patch(
      "/api/contacts/contacts/update_each",
      { contacts },
      headers(),
    );
    return res.data;
  };

  // This state is used to maintain the current contact being viewed or edited across the screen
  const [contact, setContact] = useState(null);

  const loadContact = async (id) => {
    if (!id) return;
    const res = await axios.get(`/api/contacts/contacts/${id}`);
    setLoading(false);
    return res.data;
  };

  const loadContactMedias = async (id) => {
    const res = await axios.get(`/api/contacts/contacts/${id}/medias`);
    return res.data;
  };

  const updateContact = async (contact) => {
    const res = await axios.patch(
      `/api/contacts/contacts/${contact.id}`,
      contact,
      headers(),
    );
    // update the contact state if the contact being updated is the same as the current contact
    if (res.data.id === contact.id) setContact(res.data);
    return res.data;
  };

  useEffect(() => {
    if (!contact) return;
    // update the contacts state if the contact being updated is in the contacts list
    setContacts((prev) => prev.map((c) => (c.id === contact.id ? contact : c)));
  }, [contact]);

  // Languages
  const [languages, setLanguages] = useState(["en"]);
  const loadLanguages = () => {
    axios.get("/api/contacts/languages").then((res) => {
      setLanguages(res.data);
    });
  };

  // Folders

  const addToFolder = async (contactIds, callback = (contacts) => {}) => {
    showMenu({
      title: t("inbox.folders.add_to_folder"),
      actions: organization.folders.map((folder) => ({
        label: <FolderTag folder={folder} />,
        action: async () => {
          setLoading(true);
          const res = await axios.patch(
            "/api/contacts/contacts/folder",
            { folder_id: folder.id, ids: contactIds },
            headers(),
          );
          setLoading(false);
          callback(res.data);
        },
      })),
    });
  };

  const removeFromFolder = async (contactIds, callback = (contacts) => {}) => {
    showMenu({
      title: t("inbox.folders.remove_from_folder"),
      actions: organization.folders.map((folder) => ({
        label: <FolderTag folder={folder} />,
        action: async () => {
          setLoading(true);
          const res = await axios.patch(
            "/api/contacts/contacts/folder",
            { folder_id: folder.id, ids: contactIds, remove: true },
            headers(),
          );
          setLoading(false);
          callback(res.data);
        },
      })),
    });
  };

  // Segments

  const [segments, setSegments] = useState([]);

  const loadSegments = () => {
    setLoading(true);
    axios
      .get(`/api/contacts/segments`)
      .then((res) => {
        setLoading(false);
        setSegments(res.data);
      })
      .catch((err) => console.log(err));
  };

  const createSegment = (segment, callback = () => {}) => {
    showDialog({
      label: t("contacts.segments.create_segment"),
      placeholder: "Ambassadors, VIPs, etc.",
      onSubmit: (title) => {
        setLoading(true);
        axios
          .post(
            `/api/contacts/segments`,
            {
              ...segment,
              title,
            },
            headers(),
          )
          .then((res) => {
            setLoading(false);
            setSegments([...segments, res.data]);
            callback(res.data);
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const deleteSegment = (segmentId, callback = () => {}) => {
    const segment = segments.find((s) => s.id === segmentId);
    if (!segment) return;
    showPrompt(
      t("contacts.segments.delete_segment_confirm", { segment: segment.title }),
      () => {
        setLoading(true);
        axios
          .delete(`/api/contacts/segments/${segmentId}`, headers())
          .then((res) => {
            setLoading(false);
            setSegments(res.data);
            callback();
          })
          .catch((err) => console.log(err));
      },
    );
  };

  const updateSegment = (segmentId, data) => {
    const segment = segments.find((s) => s.id === segmentId);
    if (!segment) return;
    setLoading(true);
    axios
      .put(`/api/contacts/segments/${segmentId}`, data, headers())
      .then((res) => {
        setLoading(false);
        const newSegments = segments.map((s) =>
          s.id === segmentId ? res.data : s,
        );
        setSegments(newSegments);
      })
      .catch((err) => console.log(err));
  };

  useEffect(loadSegments, [organizationId]);

  // Contact properties

  const [contactProperties, setContactProperties] = useState([]);

  const loadContactProperties = async () => {
    const res = await axios.get("/api/contacts/contact_properties");
    setContactProperties(res.data);
    return res.data;
  };

  const createContactProperty = async (property) => {
    const res = await axios.post(
      "/api/contacts/contact_properties",
      property,
      headers(),
    );
    setContactProperties([...contactProperties, res.data]);
    return res.data;
  };

  const updateContactProperty = async (property) => {
    const res = await axios.put(
      `/api/contacts/contact_properties/${property.id}`,
      property,
      headers(),
    );
    setContactProperties(
      contactProperties.map((p) => (p.id === property.id ? res.data : p)),
    );
    return res.data;
  };

  const destroyContactProperty = async (propertyId) => {
    const res = await axios.delete(
      `/api/contacts/contact_properties/${propertyId}`,
      headers(),
    );
    setContactProperties(
      contactProperties.filter((p) => parseInt(p.id) !== parseInt(propertyId)),
    );
    return res.data;
  };

  useEffect(loadContactProperties, [organizationId]);

  const contextValues = {
    loading,
    contacts,
    setContacts,
    loadContacts,
    updateContacts,
    contact,
    setContact,
    loadContact,
    loadContactMedias,
    updateContact,
    languages,
    loadLanguages,
    addToFolder,
    removeFromFolder,
    segments,
    loadSegments,
    createSegment,
    deleteSegment,
    updateSegment,
    contactProperties,
    loadContactProperties,
    createContactProperty,
    updateContactProperty,
    destroyContactProperty,
  };

  return (
    <ContactsContext.Provider value={contextValues}>
      {props.children}
    </ContactsContext.Provider>
  );
}
