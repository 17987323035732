import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React from "react";
import classNames from "../../../utils/classNames";

export default function NodeWarnings(props) {
  const { warnings } = props;

  return (
    <div className="group/warnings">
      <div className="p-1 bg-yellow-100 border border-yellow-200 rounded-lg cursor-pointer">
        <ExclamationTriangleIcon className="w-6 text-yellow-500" />
      </div>
      <div
        className={classNames(
          "pl-2 absolute top-0 left-full z-50",
          "opacity-0 group-hover/warnings:opacity-100 transition-opacity",
          "pointer-events-none group-hover/warnings:pointer-events-auto",
        )}
      >
        <div className="bg-yellow-100 border border-yellow-200 text-yellow-800 rounded-lg divide-y divide-yellow-200">
          {warnings.map((warning, index) => (
            <div
              className="p-2 text-xs font-medium whitespace-nowrap"
              key={index}
              dangerouslySetInnerHTML={{ __html: warning }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
