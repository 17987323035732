import React from "react";
import { useTranslation } from "react-i18next";

export default function ContactNotes(props) {
  const { t } = useTranslation();

  const { value, onChange } = props;

  return (
    <div className="flex-grow overflow-auto">
      <textarea
        className="w-full h-full outline-none border-none p-3 overflow-auto text-md"
        placeholder={t("contacts.notes.write_notes")}
        value={value}
        onChange={(evt) => onChange(evt.target.value)}
        autoFocus={value?.length == 0}
      ></textarea>
    </div>
  );
}
