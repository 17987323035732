import { CalendarIcon, CurrencyDollarIcon } from "@heroicons/react/24/outline";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";

import {
  ChatBubbleLeftRightIcon,
  FolderIcon,
  FunnelIcon,
  PaperAirplaneIcon,
  PlusCircleIcon,
  PresentationChartLineIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";
import {
  ArrowPathRoundedSquareIcon,
  BoltIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { useMatch, useNavigate } from "react-router-dom";
import ShadedOverflow from "~/components/utils/ShadedOverflow";
import { AutomationContext } from "~/contexts/automation-context";
import { ContactsContext } from "~/contexts/contacts-context";
import { PaymentsContext } from "~/contexts/payments-context";
import SidebarNavLink from "./SidebarNavLink";
import SidebarNavTitle from "./SidebarNavTitle";

import ReelIcon from "~/components/shared/icons/ReelIcon";
import { AgentContext } from "~/contexts/agent-context";
import classNames from "~/utils/classNames";
import robotIcon from "../../../assets/images/robot_icon.png";
import robotIconBlack from "../../../assets/images/robot_icon_black.png";
import SidebarMissingPermissions from "../profile/accounts/SidebarMissingPermissions";
import SubscriptionStatus from "../subscription/SubscriptionStatus";
import Badge from "../elements/Badge";
import SidebarAuthorizationError from "../profile/accounts/SidebarAuthorizationError";

export default function DesktopSidebar(props) {
  const { showSidebar, setShowSidebar } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    organization,
    setCreateFolderDialog,
    setEditingFolderId,
    deleteFolder,
    switchOrganization,
  } = useContext(UserContext);
  const { folders, features } = organization;
  const { segments, deleteSegment } = useContext(ContactsContext);
  const { scenarios, conversionLinks, surveys } = useContext(AutomationContext);
  const { payments } = useContext(PaymentsContext);
  const { agent } = useContext(AgentContext);

  const links = {
    dashboard: {
      label: t("navigation.dashboard"),
      path: "/dashboard",
      match: useMatch({ path: "/dashboard", end: false }),
      icon: <PresentationChartLineIcon className="w-5" />,
    },
    inbox: {
      label: t("navigation.inbox"),
      path: "/inbox/conversations",
      match: useMatch({ path: "/inbox", end: false }),
      icon: <ChatBubbleLeftRightIcon className="w-5" />,
      onClick: () => window.dispatchEvent(new CustomEvent("inboxTabClick")),
    },
    campaigns: {
      label: t("navigation.campaigns"),
      path: "/campaigns",
      match: useMatch({ path: "/campaigns", end: false }),
      icon: <PaperAirplaneIcon className="w-5" />,
    },
    contacts: {
      label: t("navigation.contacts"),
      path: "/contacts",
      match: useMatch({ path: "/contacts", end: false }),
      icon: <UserGroupIcon className="w-5" />,
    },
    calendar: {
      label: t("navigation.calendar"),
      path: "/calendar",
      match: useMatch({ path: "/calendar", end: false }),
      icon: <CalendarIcon className="w-5" />,
      onClick: () => window.dispatchEvent(new CustomEvent("calendarTabClick")),
    },
    payments: {
      label: t("navigation.payments"),
      path: "/payments",
      match: useMatch({ path: "/payments", end: false }),
      icon: <CurrencyDollarIcon className="w-5" />,
      count: payments.filter((p) => p.status == "awaiting").length,
    },
    automation: {
      label: t("navigation.automation"),
      path: "/automation",
      match: useMatch({ path: "/automation", end: false }),
      icon: <BoltIcon className="w-5" />,
    },
    content: {
      label: t("navigation.content"),
      path: "/content",
      match: useMatch({ path: "/content", end: false }),
      icon: <ReelIcon />,
    },
    agent: {
      label: t("navigation.agent"),
      path: "/agent",
      match: useMatch({ path: "/agent", end: false }),
      icon: <img src={robotIcon} className="w-5" />,
      activeIcon: <img src={robotIconBlack} className="w-5" />,
      right: features.ai ? (
        <span
          className={classNames(
            "hidden xl:block w-2 h-2 rounded-full",
            agent?.active ? "bg-green-500" : "bg-darker-gray",
          )}
        />
      ) : (
        <Badge
          label={t("navigation.beta")}
          icon={SparklesIcon}
          size="sm"
          className="hidden xl:inline-flex"
        />
      ),
    },
    profile: {
      label: organization.name,
      path: "/profile",
      match: useMatch({ path: "/profile", end: false }),
      icon: (
        <img
          className="w-5 h-5 rounded-full"
          src={organization.profile_picture}
        />
      ),
    },
  };

  const inboxFolders = folders.map((folder) => ({
    id: folder.id,
    icon: <FolderIcon className={`w-5 text-${folder.color}-500`} />,
    label: folder.name,
    path: `/inbox/folders/${folder.id}`,
    count: folder.count,
  }));

  const inboxSegments = segments.map((segment) => ({
    id: segment.id,
    icon: (
      <div className="px-1">
        <FunnelIcon className="w-3" />
      </div>
    ),
    label: segment.title,
    path: `/inbox/segments/${segment.id}`,
    count: segment.count,
  }));

  const contentLinks = [
    { id: "medias", label: t("navigation.medias"), path: "/content/medias" },
    { id: "stories", label: t("navigation.stories"), path: "/content/stories" },
  ];

  const automationLinks = [
    {
      id: "scenarios",
      label: t("navigation.scenarios"),
      path: "/automation/scenarios",
      count: scenarios.filter((s) => s.active).length,
    },
    {
      id: "links",
      label: t("navigation.conversion_links"),
      path: "/automation/conversion_links",
      count: conversionLinks.length,
    },
    {
      id: "surveys",
      label: t("navigation.surveys"),
      path: "/automation/surveys",
      count: surveys.length,
    },
  ];

  return (
    <>
      <div
        className={classNames(
          "fixed sm:static",
          "flex flex-col justify-between w-[90vw] sm:w-60 xl:w-72 flex-shrink-0 h-screen overflow-hidden bg-black p-4 pt-4 tall:pt-10 pb-4 transition-transform",
          "shadow-lg sm:shadow-none",
          showSidebar ? "" : "-translate-x-full sm:translate-x-0",
        )}
        style={{
          zIndex: 500,
        }}
      >
        <div className="flex flex-col space-y-2 tall:space-y-3 flex-grow overflow-auto scrollbar-hide mb-4">
          <div className="flex flex-col space-y-2">
            <SidebarNavLink
              link={links.dashboard}
              active={links.dashboard.match}
              level={0}
            />
            <SidebarNavLink
              link={links.inbox}
              active={links.inbox.match}
              level={0}
            />
            {links.inbox.match && (
              <div className="flex-col space-y-1 hidden xl:flex">
                <SidebarNavTitle
                  title={t("navigation.folders")}
                  action={() => setCreateFolderDialog(true)}
                />
                <ShadedOverflow height={160}>
                  {inboxFolders.map((f) => (
                    <SidebarNavLink
                      key={f.id}
                      link={f}
                      level={1}
                      actions={[
                        {
                          label: t("inbox.folders.edit"),
                          action: () => setEditingFolderId(f.id),
                        },
                        {
                          label: t("inbox.folders.delete"),
                          className: "!text-red-500",
                          action: () => deleteFolder(f.id),
                        },
                      ]}
                    />
                  ))}
                </ShadedOverflow>
                <button
                  className={`ml-4 flex items-center space-x-2 rounded-lg px-2 py-1.5 text-white opacity-60 hover:opacity-100 hover:bg-white hover:bg-opacity-10`}
                  onClick={() => setCreateFolderDialog(true)}
                >
                  <div className="p-0.5">
                    <PlusCircleIcon className="w-4" />
                  </div>
                  <div className="flex-grow text-left text-sm truncate">
                    {t("inbox.folders.create")}
                  </div>
                </button>
                {segments.length > 0 ? (
                  <>
                    <SidebarNavTitle
                      title={t("navigation.segments")}
                      className="mt-2"
                    />
                    <ShadedOverflow height={120}>
                      {inboxSegments.map((i) => (
                        <SidebarNavLink
                          key={i.id}
                          link={i}
                          level={1}
                          className="!py-1"
                          actions={[
                            {
                              label: t("contacts.segments.view_contacts"),
                              action: () =>
                                navigate(`/contacts?active_segment_id=${i.id}`),
                            },
                            {
                              label: t("contacts.segments.delete_segment"),
                              className: "!text-red-500",
                              action: () => deleteSegment(i.id),
                            },
                          ]}
                        />
                      ))}
                    </ShadedOverflow>
                  </>
                ) : null}
              </div>
            )}
          </div>

          <div className="h-1" />

          <SidebarNavLink link={links.campaigns} level={0} />
          <SidebarNavLink link={links.contacts} level={0} />
          <SidebarNavLink link={links.automation} level={0} />
          {links.automation.match && (
            <div className="flex-col space-y-1 hidden xl:flex">
              {automationLinks.map((link) => (
                <SidebarNavLink key={link.id} link={link} level={1} />
              ))}
            </div>
          )}

          <div className="h-1" />

          {/* IG Content */}
          <SidebarNavLink link={links.content} level={0} />
          {links.content.match && (
            <div className="flex-col space-y-1 hidden xl:flex">
              {contentLinks.map((link) => (
                <SidebarNavLink key={link.id} link={link} level={1} />
              ))}
            </div>
          )}

          {/* Calendar */}
          {features.calendar && (
            <SidebarNavLink link={links.calendar} level={0} />
          )}

          {/* Payments */}
          {features.payments && (
            <SidebarNavLink link={links.payments} level={0} />
          )}
          <SidebarNavLink link={links.agent} level={0} />
        </div>
        <div className="flex flex-col space-y-4">
          <div className="hidden tall:block">
            <SubscriptionStatus hidePro />
          </div>
          <div className="hidden tall:block">
            <SidebarMissingPermissions />
          </div>
          <div className="hidden tall:block">
            <SidebarAuthorizationError />
          </div>
          <div className="w-full flex gap-2 overflow-hidden">
            <SidebarNavLink
              link={links.profile}
              level={0}
              linkClassName="flex-grow overflow-hidden truncate"
            />
            <button
              className={`flex-shrink-0 rounded-lg p-2 text-white hover:bg-white hover:bg-opacity-10`}
              onClick={switchOrganization}
            >
              <ArrowPathRoundedSquareIcon className="w-4" />
            </button>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "fixed sm:hidden inset-y-0 right-0 w-[100vw] bg-white/75 transition-opacity",
          showSidebar ? "opacity-100" : "opacity-0 pointer-events-none",
        )}
        style={{
          zIndex: 400,
        }}
        onClick={() => setShowSidebar(false)}
      />
    </>
  );
}
