import React, { useState, useContext, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Cog6ToothIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

import { InboxContext } from "~/contexts/inbox-context";
import { UserContext } from "~/contexts/user-context";

import Conversation from "./Conversation";
import ConversationList from "./ConversationList";
import SyncNotification from "./SyncNotification";
import InboxStatusDialog from "./InboxStatusDialog";
import Folders from "./folders/Folders";
import ScreenSlide from "~/components/utils/ScreenSlide";
import FullScreen from "~/components/utils/FullScreen";
import InboxSettings from "../settings/InboxSettings";
import { useTranslation } from "react-i18next";
import { appInset, isApp } from "~/utils/environment";

import { ContactsContext } from "~/contexts/contacts-context";
import InboxFilters from "./InboxFilters";
import Loader from "~/components/utils/Loader";
import Input from "~/components/elements/Input";
import InputCheckbox from "~/components/elements/InputCheckbox";
import ContactPage from "../contacts/ContactPage";
import { loadInboxStatus } from "../../api/inbox/messages";

export default function InboxPage(props) {
  const { settings = false } = props;
  const { conversationId, folderId, segmentId } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { user, organization, refreshFolder } = useContext(UserContext);

  const { segments } = useContext(ContactsContext);

  const {
    loading,
    conversations,
    loadConversations,
    loadMoreConversations,
    inboxStatus,
  } = useContext(InboxContext);

  if (location.pathname == "/inbox") navigate("/inbox/conversations");

  // Search
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMessages, setSearchMessages] = useState(false);

  // Folders
  const activeFolder = organization.folders.find((f) => f.id == folderId)?.id;

  // Segments
  const activeSegment = segments.find((s) => s.id == segmentId)?.id;

  // Filters
  const [activeFilter, setActiveFilter] = useState();

  // Load conversations w/ pagination
  const handleLoadConversations = (offset = null) => {
    let params = {};
    if (folderId) params.folder_id = folderId;
    if (segmentId) params.segment_id = segmentId;
    if (activeFilter) params.filter = activeFilter.id;
    if (searchQuery) params.search = searchQuery;
    if (searchMessages) params.search_messages = true;
    if (offset) {
      loadMoreConversations(params, offset);
    } else {
      loadConversations(params);
    }
  };
  useEffect(handleLoadConversations, [
    folderId,
    segmentId,
    activeFilter,
    searchQuery,
    searchMessages,
  ]);

  // Refresh active folder info (count, unread count, etc.)
  useEffect(() => activeFolder && refreshFolder(activeFolder), [folderId]);

  // clear search when changing folder/segment
  useEffect(() => setSearchQuery(""), [folderId, segmentId]);

  // Scroll to top
  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Return to inbox when tab is clicked (mobile)
  useEffect(() => {
    const tabClick = () => {
      navigate("/inbox/conversations");
      scrollToTop();
    };
    window.addEventListener("inboxTabClick", tabClick);
    return () => window.removeEventListener("inboxTabClick", tabClick);
  }, []);

  return (
    <div className="sm:h-screen sm:flex sm:flex-grow sm:overflow-hidden">
      <FullScreen className="relative z-10 sm:min-w-[20rem] sm:max-w-sm sm:flex-grow sm:flex-shrink-0 overflow-hidden sm:border-r sm:h-full">
        <div
          className="absolute inset-x-0 top-0"
          style={{ height: appInset.top + 16 }}
          onClick={isApp ? scrollToTop : null}
        />
        <div className={`flex flex-col h-full`}>
          <div className={`bg-white pt-4 flex-shrink-0`}>
            <div className="flex items-center space-x-2 px-3 pb-2">
              <Input
                className="flex-grow"
                placeholder={t("shared.search")}
                icon={MagnifyingGlassIcon}
                value={searchQuery}
                onChange={setSearchQuery}
                clearButton
                debounce
              />
              <InboxFilters
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
              />
              <Link to="/inbox/settings" className="w-9 h-9 p-1.5">
                <Cog6ToothIcon className="w-6 h-6" />
              </Link>
            </div>
            {/* TODO implement full text search in messages */}
            {searchQuery.length && user.dev ? (
              <div className="px-3 py-1">
                <InputCheckbox
                  label={t("inbox.search_messages")}
                  value={searchMessages}
                  onClick={setSearchMessages}
                />
              </div>
            ) : null}
            {activeFilter ? (
              <div className="px-3 pt-1 flex">
                <button
                  className="flex items-center px-1.5 pt-1 pb-1.5 space-x-1 text-2xs text-primary bg-light-primary rounded"
                  onClick={() => setActiveFilter(null)}
                >
                  <div className="flex justify-center items-center space-x-1">
                    <div>{activeFilter.emoji}</div>
                    <div>{activeFilter.label}</div>
                  </div>
                  <XMarkIcon className="w-2.5 translate-y-0.25" />
                </button>
              </div>
            ) : null}
            <Folders
              folders={organization.folders}
              activeFolder={activeFolder}
            />
          </div>
          {loading ? (
            <div className="flex items-center justify-center p-4">
              <Loader strokeWidth={8} />
            </div>
          ) : (
            <ConversationList
              conversations={conversations}
              activeFilter={activeFilter}
              activeFolder={activeFolder}
              onRefresh={handleLoadConversations}
              onReachEnd={handleLoadConversations}
              scrollRef={scrollRef}
            />
          )}
        </div>
        <div className="absolute z-50 inset-x-4 bottom-3 space-y-4">
          {!organization.instagram_synced &&
            organization.instagram_connected && (
              <SyncNotification
                title={t("inbox.sync_in_progress")}
                text={t("inbox.sync_in_progress_text")}
              />
            )}
          {organization.instagram_synced &&
            organization.instagram_connected &&
            inboxStatus.pending_messages > 0 && (
              <SyncNotification
                title={t("inbox.pending_messages_title", {
                  count: inboxStatus.pending_messages,
                })}
                text={t("inbox.pending_messages_text")}
              />
            )}
          {organization && <InboxStatusDialog />}
        </div>
      </FullScreen>

      {/* Contact */}
      <ScreenSlide
        visible={!!conversationId}
        origin="/inbox"
        stackDepth={1}
        desktopInline
        hideTabBar
      >
        {conversationId && <ContactPage conversationId={conversationId} />}
      </ScreenSlide>

      {/* Conversation */}
      {/* <ScreenSlide
        visible={!!conversationId}
        origin="/inbox"
        stackDepth={1}
        desktopInline
        hideTabBar
      >
        {conversationId && <Conversation conversationId={conversationId} />}
      </ScreenSlide> */}

      {/* Inbox Settings */}
      <ScreenSlide visible={settings} origin="/inbox" stackDepth={1}>
        <InboxSettings />
      </ScreenSlide>
    </div>
  );
}
