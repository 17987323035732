import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OptOutKeywords(props) {
  const { t } = useTranslation();
  const { keywords = [], updateKeywords } = props;

  const inputRef = useRef();
  const [newKeyword, setNewKeyword] = useState("");

  const addKeyword = () => {
    if (newKeyword) {
      updateKeywords([...keywords, newKeyword]);
      setNewKeyword("");
    }
    inputRef.current.focus();
  };

  const removeKeyword = (index) => {
    const updatedKeywords = [
      ...keywords.slice(0, index),
      ...keywords.slice(index + 1),
    ];
    updateKeywords(updatedKeywords);
  };

  return (
    <div className="p-4 space-y-3">
      <div className="font-medium">{t("opt_out.keywords")}</div>
      <div className="flex flex-wrap items-start gap-2">
        {keywords.map((keyword, index) => (
          <div
            key={index}
            className="pl-2 py-1 pr-1.5 rounded-xl bg-neutral-200 text-xs"
          >
            {keyword}
            <XMarkIcon
              className="inline-block w-3 ml-1 cursor-pointer"
              onClick={() => removeKeyword(index)}
            />
          </div>
        ))}
        <input
          ref={inputRef}
          type="text"
          placeholder={t("opt_out.new_keyword")}
          className="px-2 py-1 rounded-xl bg-neutral-100 text-xs outline-none border-none"
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && addKeyword()}
        />
      </div>
    </div>
  );
}
