import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "~/contexts/user-context";
import PageHeader from "~/components/shared/PageHeader";
import FullScreen from "~/components/utils/FullScreen";

import Button from "~/components/elements/Button";
import { SubscriptionContext } from "../../contexts/subscription-context";

export default function OrganizationUsersPage(props) {
  const { back } = props;

  const { organization, user, inviteUser, removeUser } =
    useContext(UserContext);
  const { limits } = useContext(SubscriptionContext);

  const { t } = useTranslation();

  return (
    <FullScreen>
      <PageHeader
        title={t("profile.organization_users.title")}
        back={back}
        className="mb-4"
      />
      <div className="border-t">
        {organization.organization_users?.map((ou) => (
          <div
            key={ou.id}
            className="px-3 py-3 border-b flex items-center justify-between space-x-3"
          >
            <div className="text-md">{ou.user.name}</div>
            <div className="text-md text-dark-gray flex-grow">
              {ou.user.email}
            </div>
            {user.organization_users.find(
              (ou) => ou.organization.id === organization.id,
            )?.role === "owner" &&
              (ou.role === "owner" ? (
                <Button
                  label={t("profile.organization_users.owner")}
                  size="small"
                  style="soft"
                  disabled
                />
              ) : (
                <Button
                  label={t("profile.organization_users.remove_user")}
                  size="small"
                  style="danger"
                  onClick={() => {
                    removeUser(ou.id);
                  }}
                />
              ))}
          </div>
        ))}
      </div>
      {/* Only show button if can invite members */}
      {limits.members &&
      organization.organization_users.length >= limits.members ? null : (
        <div className="px-3 py-6">
          <Button
            label={t("profile.organization_users.add_user")}
            className="w-full"
            onClick={inviteUser}
          />
        </div>
      )}
    </FullScreen>
  );
}
