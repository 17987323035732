import React from "react";
import ActionNode from "./ActionNode";
import { useTranslation } from "react-i18next";

export default function BranchOptionNode(props) {
  const { t } = useTranslation();

  const { data } = props;
  const { label, message } = data;

  return (
    <ActionNode noEdit noWarnings {...props}>
      <div className="w-full space-y-2 flex flex-col items-center">
        {label && (
          <div className="text-3xs uppercase font-bold text-neutral-400 text-center">
            {t(label)}
          </div>
        )}
        {message && (
          <div className="flex items-center space-x-1 border rounded-2xl bg-white pl-2.5 pr-2 py-1">
            <div className="text-sm">{message}</div>
          </div>
        )}
      </div>
    </ActionNode>
  );
}
