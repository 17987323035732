import React, { useContext, useEffect, useState } from "react";
import { ContactsContext } from "../../contexts/contacts-context";
import { appInset } from "../../utils/environment";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AtSymbolIcon,
  BoltIcon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  ChevronLeftIcon,
  DocumentTextIcon,
  IdentificationIcon,
  PaperAirplaneIcon,
} from "@heroicons/react/20/solid";
import ContactHeader from "./ContactHeader";
import EditContactFolders from "../inbox/folders/EditContactFolders";
import Tabs from "../elements/Tabs";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "../utils/ErrorComponent";
import { InboxContext } from "../../contexts/inbox-context";
import Conversation from "../inbox/Conversation";
import Button from "../elements/Button";
import Modal from "../shared/Modal";
import EditContact from "./EditContact";
import ConversationScenarioExecutions from "../automation/scenarios/executions/ConversationScenarioExecutions";
import ContactCampaignExecutions from "./ContactCampaignExecutions";
import ContactComments from "./ContactComments";
import ContactMentions from "./ContactMentions";
import ContactStatus from "./ContactStatus";
import ContactNotes from "./ContactNotes";

export default function ContactPage(props) {
  const { t } = useTranslation();

  const { contactId, conversationId, back } = props;

  const navigate = useNavigate();
  const handleBack = () => {
    if (back) {
      back();
    } else {
      navigate(-1);
    }
  };

  const { contact, setContact, loadContact } = useContext(ContactsContext);
  const { loadConversation } = useContext(InboxContext);

  const [loading, setLoading] = useState(true);

  // this little trick for loading this component with a conversationId and not a contactId
  useEffect(async () => {
    setLoading(true);
    if (contactId) {
      const contact = await loadContact(contactId);
      setContact(contact);
    } else if (conversationId) {
      const conversation = await loadConversation(conversationId);
      setContact(conversation?.contact);
    }
    setLoading(false);
  }, [contactId, conversationId]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "conversation",
  );
  const tabs = [
    {
      id: "conversation",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      id: "automation",
      icon: BoltIcon,
      count: contact?.automation_count,
    },
    {
      id: "campaigns",
      icon: PaperAirplaneIcon,
      count: contact?.campaigns_count,
    },
    {
      id: "comments",
      icon: ChatBubbleBottomCenterTextIcon,
      count: contact?.comments_count,
    },
    {
      id: "mentions",
      icon: AtSymbolIcon,
      count: contact?.mentions_count,
    },
  ];

  const [editContact, setEditContact] = useState(
    searchParams.get("edit") || false,
  );

  useEffect(() => {
    const params = {};
    if (activeTab) params.tab = activeTab;
    if (editContact) params.edit = editContact;
    setSearchParams(params);
  }, [activeTab, editContact]);

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <div
        className={`h-full sm:flex-grow flex overflow-hidden`}
        style={{
          paddingTop: appInset.top,
        }}
      >
        <div className="overflow-hidden flex flex-col items-stretch flex-1">
          <div
            className={`bg-white border-b flex-shrink-0 flex items-start p-3`}
          >
            <button
              onClick={handleBack}
              className="w-8 h-9 flex justify-items-center items-center sm:hidden"
            >
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
            <div className={"flex-grow space-y-3"}>
              <div className="flex justify-start items-start space-x-4">
                <ContactHeader
                  contact={contact}
                  loading={loading}
                  linkToProfile
                />
                {contact && <ContactStatus contact={contact} />}
              </div>

              <EditContactFolders
                contact={contact}
                loading={loading}
                className="pl-10 pr-4"
              />
            </div>
            {!editContact && (
              <Button
                label={t("contacts.edit")}
                icon={IdentificationIcon}
                onClick={() => setEditContact(true)}
                className="hidden sm:flex"
              />
            )}
          </div>
          <Tabs
            tabs={tabs.map((tab) => ({
              id: tab.id,
              icon: tab.icon,
              count: tab.count || null,
              title: t("contacts.tabs." + tab.id),
              active: activeTab == tab.id,
              onClick: () => setActiveTab(tab.id),
            }))}
          />
          {activeTab == "conversation" && (
            <Conversation conversationId={contact?.conversation_id} />
          )}
          {activeTab == "automation" && (
            <ConversationScenarioExecutions
              conversationId={contact?.conversation_id}
            />
          )}
          {activeTab == "campaigns" && (
            <ContactCampaignExecutions contactId={contact?.id} />
          )}
          {activeTab == "comments" && (
            <ContactComments contactId={contact?.id} />
          )}
          {activeTab == "mentions" && (
            <ContactMentions contactId={contact?.id} />
          )}
          {activeTab == "notes" && <ContactNotes contactId={contact?.id} />}
        </div>
        {editContact && (
          <div className="flex-shrink-0 border-l">
            <EditContact
              contactId={contact?.id}
              onClose={() => setEditContact(false)}
            />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
}
