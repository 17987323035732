import { TrashIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Input from "~/components/elements/Input";
import Button from "~/components/elements/Button";
import { FlowContext } from "../../../../contexts/flow-context";

const MAX_KEYWORDS = 4;

export default function ActionKeywordsOption(props) {
  const { t } = useTranslation();

  const { action } = props;
  const { id, options } = action;
  const { keywords } = options;

  const { editAction } = useContext(FlowContext);

  const editKeywords = (keywords) => {
    editAction(id, {
      options: {
        ...options,
        keywords,
      },
    });
  };

  const addKeyword = () => editKeywords([...(keywords || []), ""]);

  const updateKeyword = (index, keyword) => {
    // check if actual change or just state change
    if (keywords[index] === keyword) return;
    editKeywords([
      ...keywords.slice(0, index),
      keyword,
      ...keywords.slice(index + 1),
    ]);
  };

  const removeKeyword = (index) => {
    editKeywords([...keywords.slice(0, index), ...keywords.slice(index + 1)]);
  };

  return (
    <div className="flex flex-col space-y-2">
      {keywords?.map((keyword, index) => (
        <div key={index} className="flex items-center space-x-2">
          <Input
            value={keyword || ""}
            onChange={(value) => updateKeyword(index, value)}
            placeholder={t(
              `automation.scenarios.actions.keywords.keyword_placeholder`,
            )}
            className="flex-grow"
            autoFocus={!keyword?.length}
            emojiPicker
            changeOnBlur
          />
          {keywords.length <= MAX_KEYWORDS && (
            <Button
              icon={TrashIcon}
              size="small"
              onClick={() => removeKeyword(index)}
            />
          )}
        </div>
      ))}
      <Button
        label={t(`automation.scenarios.actions.keywords.add_keyword`)}
        size="small"
        onClick={addKeyword}
        className="self-start"
      />
    </div>
  );
}
