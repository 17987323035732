import React, { useContext, useEffect, useState } from "react";
import Modal from "../shared/Modal";
import { useTranslation } from "react-i18next";
import Loader from "../utils/Loader";
import {
  createIcebreaker,
  deleteIcebreaker,
  getIcebreakers,
} from "../../api/organization/icebreakers";
import Button from "../elements/Button";
import { QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { UIContext } from "../../contexts/ui-context";
import classNames from "../../utils/classNames";
import Input from "../elements/Input";

export default function IcebreakersModal(props) {
  const { t } = useTranslation();

  const { showPrompt } = useContext(UIContext);

  const { onPick, onClose } = props;

  const [loading, setLoading] = useState(true);
  const [icebreakers, setIcebreakers] = useState([]);
  const [newIcebreaker, setNewIcebreaker] = useState("");

  const loadIcebreakers = async () => {
    setLoading(true);
    const icebreakers = await getIcebreakers();
    setIcebreakers(icebreakers);
    setLoading(false);
  };

  useEffect(loadIcebreakers, []);

  const handleDeleteIcebreaker = (icebreaker) => {
    showPrompt(t("icebreakers.delete_confirm"), async () => {
      setLoading(true);
      await deleteIcebreaker(icebreaker.payload);
      loadIcebreakers();
    });
  };

  const handleAddIcebreaker = () => {
    if (!newIcebreaker) return;
    showPrompt(t("icebreakers.add_confirm"), async () => {
      setLoading(true);
      setNewIcebreaker("");
      const res = await createIcebreaker(newIcebreaker);
      setIcebreakers(res);
      setLoading(false);
    });
  };

  const handlePick = (icebreaker) => {
    if (onPick) {
      onPick(icebreaker);
      onClose();
    }
  };

  return (
    <Modal onClose={onClose} title={t("icebreakers.title")}>
      <div className="p-4 space-y-4 w-96 max-w-full">
        {loading && (
          <div className="flex justify-center p-4">
            <Loader />
          </div>
        )}
        {!loading &&
          icebreakers.map((icebreaker) => (
            <div
              key={icebreaker.payload}
              className="flex items-center justify-between space-x-4"
            >
              <button
                className={classNames(
                  "text-message icebreaker",
                  onPick && "cursor-pointer hover:opacity-90",
                )}
                alt={icebreaker.payload}
                onClick={() => handlePick(icebreaker)}
              >
                {icebreaker.question}
              </button>
              <Button
                size="small"
                icon={TrashIcon}
                onClick={() => handleDeleteIcebreaker(icebreaker)}
              />
            </div>
          ))}
        {!loading && icebreakers.length === 0 && (
          <div className="text-center text-neutral-500 text-sm p-2">
            {t("icebreakers.no_icebreakers")}
          </div>
        )}
        {!loading && icebreakers.length < 4 && (
          <div className="flex space-x-2">
            <Input
              value={newIcebreaker}
              onChange={setNewIcebreaker}
              placeholder={t("icebreakers.new_icebreaker_placeholder")}
              className="flex-grow"
              size="small"
            />
            <Button
              label={t("shared.add")}
              size="small"
              onClick={handleAddIcebreaker}
              disabled={!newIcebreaker}
            />
          </div>
        )}
        <div className="flex space-x-1">
          <QuestionMarkCircleIcon className="w-4 text-neutral-500" />
          <a
            href="https://developers.facebook.com/docs/messenger-platform/instagram/features/ice-breakers#getting-ice-breakers"
            target="_blank"
            className="block text-xs text-neutral-600 underline"
          >
            {t("icebreakers.learn_more")}
          </a>
        </div>
      </div>
    </Modal>
  );
}
