import { QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { keys } from "lodash";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import Modal from "~/components/shared/Modal";
import { ContactsContext } from "../../../contexts/contacts-context";

const variables = {
  contact: [
    "id",
    "username",
    "name",
    "email",
    "phone",
    "follower_count",
    "profile_picture",
  ],
  trigger: ["message", "comment", "date", "ref"],
  survey: ["date", "option.title", "option.replies", "option.percentage"],
};

export default function InsertVariable(props) {
  const { onClose, onSelect, categories = ["contact", "trigger"] } = props;

  const { contactProperties } = useContext(ContactsContext);

  const { t } = useTranslation();

  const handleSelect = (variable) => {
    onSelect(variable);
    onClose();
  };

  const renderCategory = (category) => {
    if (!variables[category]) return null;

    const categoryVariables = variables[category].map((variable) => ({
      value: `{{${category}.${variable}}}`,
      label: t(`automation.variables.${category}.fields.${variable}`),
    }));
    if (category == "contact") {
      contactProperties.forEach((property) => {
        categoryVariables.push({
          value: `{{${category}.property_${property.id}}}`,
          label: property.title,
        });
      });
    }
    return (
      <div className="space-y-2">
        <div className="font-medium text-sm">
          {t(`automation.variables.${category}.title`)}
        </div>
        <div className="text-xs text-neutral-600">
          {t(`automation.variables.${category}.description`)}
        </div>
        <div className="flex flex-wrap gap-2">
          {categoryVariables.map((variable) => (
            <Button
              label={variable?.label}
              onClick={() => handleSelect(variable.value)}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <Modal
      onClose={onClose}
      zIndex={1000}
      className="max-w-[520px]"
      title={t("automation.scenarios.actions.message.insert_variable")}
    >
      <div className="p-4 space-y-4">
        {categories.map(renderCategory)}
        <div className="flex space-x-1">
          <QuestionMarkCircleIcon className="w-4 text-neutral-500" />
          <a
            href="https://help.inro.social/en/articles/9420462-how-to-use-custom-variables"
            target="_blank"
            className="block text-xs text-neutral-600 underline"
          >
            {t("automation.variables.learn_more")}
          </a>
        </div>
      </div>
    </Modal>
  );
}
