import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import Button from "~/components/elements/Button";
import QuestionsSurveyPreview from "./QuestionsSurveyPreview";
import QuestionsSurveyFormQuestion from "./QuestionsSurveyFormQuestion";

export default function QuestionsSurveyForm(props) {
  const { t } = useTranslation();

  const { survey, questions, setQuestions } = props;

  const visibleQuestions = questions.filter((o) => !o._destroy);

  const addQuestion = () => {
    setQuestions((questions) => [
      ...questions,
      { title: "", field_type: "text" },
    ]);
  };

  const removeQuestion = (index) => {
    const question = questions[index];
    if (question.id) {
      setQuestions((questions) =>
        questions.map((o) =>
          o.id === question.id ? { ...o, _destroy: true } : o,
        ),
      );
    } else {
      setQuestions((questions) => questions.filter((_, i) => i !== index));
    }
  };

  const changeQuestion = (index, value) => {
    setQuestions((questions) =>
      questions.map((q, i) => (i == index ? { ...q, ...value } : q)),
    );
  };

  return (
    <div className="flex flex-col sm:flex-row items-stretch overflow-hidden min-h-[500px]">
      {questions.length === 0 && (
        <div className="w-full flex flex-col items-center justify-center space-y-4">
          <div className="text-sm text-muted">
            {t("surveys.questions.no_questions")}
          </div>
          <Button
            onClick={addQuestion}
            label={t("surveys.questions.add_question")}
            icon={PlusIcon}
          />
        </div>
      )}
      {questions.length > 0 && (
        <div className="w-full flex flex-col">
          <div className="space-y-4 p-3 overflow-y-auto flex-grow">
            {questions.map(
              (question, index) =>
                !question._destroy && (
                  <div className="space-y-2">
                    <div className="font-bold uppercase text-2xs">
                      {t("surveys.questions.question_number", {
                        number: index + 1,
                      })}
                    </div>
                    <QuestionsSurveyFormQuestion
                      key={index}
                      question={question}
                      onChange={(value) => changeQuestion(index, value)}
                      onRemove={() => removeQuestion(index)}
                    />
                  </div>
                ),
            )}
          </div>
          <div className="flex justify-between items-center border-t p-3">
            <div className="font-medium text-sm">
              {t("surveys.questions.count", { count: visibleQuestions.length })}
            </div>
            <Button
              size="small"
              onClick={addQuestion}
              label={t(`surveys.questions.add_question`)}
              icon={PlusIcon}
            />
          </div>
        </div>
      )}
      <div className="w-full h-full overflow-auto">
        <QuestionsSurveyPreview
          survey={survey}
          questions={visibleQuestions}
          className="w-full min-h-full p-8"
        />
      </div>
    </div>
  );
}
