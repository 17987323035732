import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import jstz from "jstimezonedetect";
import i18n from "../locales/i18n";
import { useTranslation } from "react-i18next";
import { compact, throttle } from "lodash";

import { UserContext } from "~/contexts/user-context";
import { LoadingContext } from "~/contexts/loading-context";
import { UIContext } from "~/contexts/ui-context";

import Loading from "../components/shared/Loading";
import FolderDialog from "../components/inbox/folders/FolderDialog";

import reactNativeMessage from "~/utils/reactNativeMessage";
import subscribeToChannel from "~/utils/subscribeToChannel";
import headers from "~/utils/headers";

import InstagramAlreadyConnected from "../components/errors/InstagramAlreadyConnected";
import { switchToOrganization } from "../api/organizations";

export const requiredFacebookScopes = [
  "public_profile",
  "instagram_basic",
  "instagram_manage_messages",
  "instagram_manage_insights",
  "instagram_manage_comments",
  "pages_show_list",
  "pages_manage_metadata",
  "pages_read_engagement",
];

export default function UserProvider(props) {
  const { t } = useTranslation();

  // User
  const [user, setUser] = useState(props.user);
  const [organization, setOrganization] = useState(props.organization);
  const { showAlert, showMenu, showDialog, handleError, notificationsGranted } =
    useContext(UIContext);
  const { setLoading } = useContext(LoadingContext);

  // send a message to the webview (mobile app)
  const sendSignInMessage = () => {
    if (user && organization) reactNativeMessage({ user, organization });
  };
  useEffect(sendSignInMessage, [user, organization]);

  const initialize = () => {
    // User
    loadUser(() => setLoading(false));
    subscribeToChannel("UserChannel", (data) => {
      setUser(data);
    });
    // Organization
    loadOrganization(() => setLoading(false));
    subscribeToChannel("OrganizationChannel", (data) => {
      setOrganization(data);
    });
  };

  const loadUser = useCallback(
    throttle((callback) => {
      if (document.visibilityState == "hidden") return;
      axios.get("/api/user").then((res) => {
        setUser(res.data);
        if (callback) callback();
      });
    }, 1000),
    [],
  );

  const loadOrganization = useCallback(
    throttle((callback) => {
      if (document.visibilityState == "hidden") return;
      axios.get("/api/organization").then((res) => {
        setOrganization(res.data);
        if (callback) callback();
      });
    }, 1000),
    [],
  );

  useEffect(initialize, []);

  // Update user
  const updateUser = (data) => {
    setUser({ ...user, ...data });
    axios
      .patch("/api/user", { user: data }, headers())
      .then((res) => setUser(res.data));
  };

  // delete user
  const deleteUser = () => {
    showAlert({
      title: t("shared.warning"),
      message: t("profile.delete_confirm"),
      confirm: t("profile.delete_action"),
      onSubmit: () => {
        axios.delete("/api/user", headers()).then((res) => {
          setUser(res.data);
          showAlert({
            message: t("profile.delete_confirmation"),
            confirm: t("shared.ok"),
          });
        });
      },
    });
  };

  const cancelDeleteUser = () => {
    showAlert({
      title: t("profile.cancel_delete"),
      onSubmit: () => {
        axios.delete("/api/user?cancel=true", headers()).then((res) => {
          setUser(res.data);
        });
      },
    });
  };

  // Invite user to organization
  const inviteUser = () => {
    showDialog({
      label: t("profile.organization_users.add_user_dialog"),
      placeholder: "email@domain.com",
      onSubmit: (email) => {
        try {
          axios.post("/api/user", { email }, headers()).then((res) => {
            setOrganization(res.data);
          });
        } catch (error) {
          handleError(error);
        }
      },
    });
  };

  // Remove user from organization
  const removeUser = (id) => {
    showAlert({
      title: t("profile.organization_users.remove_user_confirm"),
      confirm: t("shared.yes"),
      cancel: t("shared.no"),
      onSubmit: (email) => {
        axios.delete(`/api/organization_users/${id}`, headers()).then((res) => {
          setOrganization(res.data);
        });
      },
    });
  };

  // Create organization
  const createOrganization = () => {
    showDialog({
      label: t("profile.create_organization"),
      placeholder: t("profile.organization_name"),
      onSubmit: (name) => {
        axios
          .post("/api/organization", { organization: { name } }, headers())
          .then((res) => {
            setOrganization(res.data);
          });
      },
    });
  };

  // Complete onboarding
  const completeOnboarding = async () => {
    const res = await axios.patch(
      "/api/organization/complete_onboarding",
      {},
      headers(),
    );
    setOrganization(res.data);
    return res.data;
  };

  // Business Discovery
  const businessDiscovery = async (username) => {
    const res = await axios.get("/api/organization/business_discovery", {
      params: { username },
    });
    return res.data;
  };

  // Change current organization
  const switchOrganization = async () => {
    showMenu({
      title: t("profile.switch_organization"),
      actions: [
        ...user.organization_users.map((ou) => ({
          label: (
            <div className="flex items-center space-x-2">
              <img
                className="w-5 h-5 rounded-full"
                src={ou.organization.profile_picture}
              />
              <div>
                {ou.organization.name} - @{ou.organization.username}
              </div>
            </div>
          ),
          action: () => switchToOrganization(ou.organization.id),
        })),
        {
          label: (
            <span className="text-darker-gray">
              {t("profile.create_organization")}
            </span>
          ),
          action: createOrganization,
        },
      ],
    });
  };

  // Leave organization
  const leaveOrganization = () => {
    showAlert({
      title: t("profile.leave_organization_confirm"),
      onSubmit: () => {
        axios.delete(`/api/organization/leave`, headers()).then((res) => {
          setOrganization(res.data);
        });
      },
    });
  };

  // Delete organization
  const deleteOrganization = () => {
    if (user.organization_users.length == 1) return;
    showAlert({
      title: t("profile.delete_organization_confirm"),
      onSubmit: () => {
        axios.delete("/api/organization", headers()).then((res) => {
          setOrganization(res.data);
        });
      },
    });
  };

  // Update organization
  const updateOrganization = async (data) => {
    setOrganization({ ...organization, ...data });
    const res = await axios.patch(
      "/api/organization",
      { organization: data },
      headers(),
    );
    setOrganization(res.data);
    return res.data;
  };

  // Load dashboard content
  const loadDashboardData = async (params) => {
    const res = await axios.get("/api/dashboard", { params });
    return res.data;
  };

  // Track event
  const trackEvent = (event, properties = {}) => {
    axios.post("/api/user/track", { event, properties }, headers());
  };

  // Check Instagram DM permissions
  const checkPermissions = () => {
    axios.get("/api/organization/check_permissions").then((res) => {
      setOrganization(res.data);
    });
  };

  const missingPermissions = requiredFacebookScopes.filter(
    (scope) => !organization.facebook_api_scopes?.includes(scope),
  );

  // Check API status
  const checkStatus = () => {
    axios.get("/api/organization/check_status").then((res) => {
      setOrganization(res.data);
    });
  };

  // Notifications

  // If activating, wait for user to confirm (and the native prompt to appear) to update state + api
  const updateNotificationSettings = (key, value) => {
    axios
      .patch(
        `/api/user`,
        {
          user: {
            notification_settings: {
              ...user.notification_settings,
              [key]: value,
            },
          },
        },
        headers(),
      )
      .then((res) => setUser(res.data));
    if (value && value != "disabled")
      reactNativeMessage({ notification: true });
  };

  // Folders

  // Refresh folder
  const refreshFolder = async (folderId) => {
    const res = await axios.get("/api/inbox/folders/" + folderId, headers());
    setOrganization((organization) => ({
      ...organization,
      folders: organization.folders.map((f) =>
        f.id == folderId ? res.data : f,
      ),
    }));
  };

  // Create folder
  const [createFolderDialog, setCreateFolderDialog] = useState(false);
  const createFolder = async (folder) => {
    try {
      const result = await axios.post(
        "/api/inbox/folders",
        { folder },
        headers(),
      );

      const folders = [...organization.folders, result.data];

      setOrganization((organization) => ({
        ...organization,
        folders,
      }));

      return result.data;
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  // Edit & notifications
  const [editingFolderId, setEditingFolderId] = useState(null);
  const editingFolder = useMemo(
    () => organization.folders?.find((f) => f.id == editingFolderId),
    [organization.folders, editingFolderId],
  );
  const editFolder = (folder) => {
    const folders = organization.folders.map((f) =>
      f.id == folder.id ? folder : f,
    );
    setOrganization({ ...organization, folders });
    axios
      .patch("/api/inbox/folders/" + folder.id, { folder }, headers())
      .then((res) => setOrganization(res.data));
  };

  // Folder menu
  const showFolderMenu = (folder) => {
    showMenu({
      title: folder.name,
      actions: compact([
        {
          label: t("inbox.folders.manage"),
          // TODO - do this better without page reload
          action: () => window.location.replace("/inbox/settings/folders"),
        },
        {
          label: t("inbox.folders.edit"),
          action: () => setEditingFolderId(folder),
        },
        {
          label:
            folder.notifications_setting == "none"
              ? t("inbox.folders.activate_notifications")
              : t("inbox.folders.manage_notifications"),
          action: () => showFolderNotificationsMenu(folder),
        },
        {
          label: t("inbox.folders.delete"),
          action: () => deleteFolder(folder.id),
          className: "text-red-500",
        },
      ]),
    });
  };

  // Folder notifications menu
  const notificationOptions = ["none", "real_time", "daily", "weekly"];
  const updateFolderNotificationsSetting = (folder, setting) => {
    editFolder({ ...folder, notifications_setting: setting });
    if (setting != "none" && !notificationsGranted)
      reactNativeMessage({ notification: true });
  };
  const showFolderNotificationsMenu = (folder) => {
    showMenu({
      title:
        folder.notifications_setting == "none"
          ? t("inbox.folders.activate_notifications")
          : t("inbox.folders.manage_notifications"),
      actions: notificationOptions.map((option) => ({
        label: (
          <div
            className={`flex items-center justify-center ${
              option == folder.notifications_setting
                ? "font-semibold"
                : "font-normal"
            }`}
          >
            {t("inbox.folders.notifications_" + option)}
          </div>
        ),
        action: () => updateFolderNotificationsSetting(folder, option),
      })),
    });
  };

  // Delete folder
  const deleteFolder = (folderId) => {
    const folder = organization.folders.find((f) => f.id == folderId);
    if (!folder) return;
    showAlert({
      title: t("inbox.folders.delete_confirm", {
        folder: folder.name,
      }),
      message: t("inbox.folders.delete_confirm_notice"),
      confirm: t("shared.delete"),
      onSubmit: () => confirmDeleteFolder(folder.id),
    });
  };
  const confirmDeleteFolder = (folderId) => {
    const folders = organization.folders.filter(
      (folder) => folder.id != folderId,
    );
    setOrganization({ ...organization, folders });
    axios
      .delete("/api/inbox/folders/" + folderId, headers())
      .then((res) => setOrganization(res.data));
  };

  // Update instagram info
  const updateInstagramInfo = async () => {
    if (!organization) return;

    const res = await axios.patch(
      "/api/organization/update_instagram_info",
      null,
      headers(),
    );
    setOrganization(res.data);
  };

  useEffect(() => {
    updateInstagramInfo();
  }, [organization.id]);

  // Update user locale
  const updateLocaleSettings = async () => {
    if (!user) return;

    if (user.locale == undefined) return;

    let locale = i18n.language.split("-")[0];
    // default to english if locale not supported
    if (!["en", "fr"].includes(locale)) locale = "en";

    if (user.locale == locale) return;

    const res = await axios.patch("/api/user", { user: { locale } }, headers());
    setUser(res.data);
  };

  useEffect(updateLocaleSettings, [user.locale]);

  // Set initial timezone
  const setInitialTimezone = async () => {
    if (organization.time_zone == undefined) return;

    const tz = jstz.determine().name();
    if (!tz) return;

    // only update if necessary
    if (organization.time_zone == tz) return;

    await axios.patch(
      "/api/organization/time_zone",
      { time_zone: tz },
      headers(),
    );
  };

  useEffect(setInitialTimezone, [organization.timezone]);

  const userValues = {
    user,
    updateUser,
    setUser,
    loadUser,
    deleteUser,
    cancelDeleteUser,
    inviteUser,
    removeUser,
    completeOnboarding,
    businessDiscovery,
    switchOrganization,
    leaveOrganization,
    deleteOrganization,
    organization,
    setOrganization,
    updateOrganization,
    loadOrganization,
    loadDashboardData,
    trackEvent,
    checkStatus,
    checkPermissions,
    missingPermissions,
    updateNotificationSettings,
    refreshFolder,
    createFolder,
    setCreateFolderDialog,
    showFolderMenu,
    showFolderNotificationsMenu,
    setEditingFolderId,
    deleteFolder,
  };

  if (user.loading || organization.loading) return <Loading />;

  return (
    <UserContext.Provider value={userValues}>
      {props.children}

      {/* Organization errors */}

      {organization?.facebook_error_code == "onboarding" &&
        organization?.facebook_error_message ==
          "Instagram account already connected" && (
          <InstagramAlreadyConnected />
        )}

      {createFolderDialog && (
        <FolderDialog
          folder={null}
          onSubmit={createFolder}
          onClose={() => setCreateFolderDialog(false)}
        />
      )}
      {editingFolder && (
        <FolderDialog
          folder={editingFolder}
          onSubmit={editFolder}
          onClose={() => setEditingFolderId(false)}
        />
      )}
    </UserContext.Provider>
  );
}
